import { FC, useState } from "react";
import { IAccordionWrapper } from "./IAccordionWrapper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { CustomIconButton } from "../UI/Buttons/CustomIconButton/CustomIconButton";

export const  AccordionWrapper: FC<IAccordionWrapper> = (props) => {
  const {
    children,
    sxPropsAccord,
    sxPropsAccordSummary,
    externalControl,
    setIsOpen,
    title,
    isOpen = false,
    sxPropsText,
    showDivider = true,
    leftText,
    sxPropsLeftText,
    sxAccordionDetails,
    readOnly
  } = props;

  const [isOpenAccordion, setOpenAccordion] = useState(isOpen);

  const expanded = externalControl ? isOpen : isOpenAccordion;
  const toggleAccordion = () => {
    if (externalControl && setIsOpen) {
      setIsOpen(!isOpen);
    } else {
      setOpenAccordion((prev) => !prev);
    }
  };

  return (
    <div>
      <Accordion
        elevation={0}
        expanded={expanded}
        disableGutters
        TransitionProps={{ timeout: 0 }}
        sx={{
          pl: 0,
          pr: 0,
          background: "inherit",
          overflow: "hidden",
          ...sxPropsAccord,
        }}>
        <AccordionSummary
          expandIcon={
            <CustomIconButton disabled={readOnly}>
              <ExpandMore fontSize="medium" sx={{ transform: "rotate(-90deg)" }} />
            </CustomIconButton>
          }
          onClick={() => !readOnly && toggleAccordion()}
          sx={{
            pl: 0,
            pr: 0,
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            ...sxPropsAccordSummary,
          }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: "bold", ml: 0.5, ...sxPropsText }}>{title}</Typography>
            <Typography sx={{ fontWeight: "bold", ...sxPropsLeftText }}>{leftText}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 0, pr: 0, ...sxAccordionDetails }}>{children}</AccordionDetails>
      </Accordion>
      {!isOpenAccordion && showDivider && <Divider />}
    </div>
  );
};
