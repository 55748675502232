import { makeAutoObservable } from "mobx";
import {
  IGuaranteeNotCompletedItem,
  IMotivationCalcAll,
  IMotivationCoefsSizeAudienctResponse,
  IMotivationPercentForSalesResponse,
  IMotivationRoiCoef,
  IMotivationRoiRow,
  IMotivationSearchPricesResponse,
  IMotivationThreshold,
} from "../services/motivation/IMotivationResponse";
import { IAgreementsResponseCreate } from "../services/agreements/IAgreementsResponse";
import { IStatusAgreementItem } from "../components/Statuses/models/ISettingsFilters";
import {
  IReferencesBudgetItem,
  IReferencesPlanGetResponse,
} from "../services/references/IReferencesResponse";
import { IDragParams, setCheckedParams } from "./integrationTable.store";
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";

class DashboardStore {
  isLoadingSort: boolean = false;

  searchIntegrationId: number | null = null;

  agreementsAll: null | IAgreementsResponseCreate[] = null;

  budgetAll: null | IReferencesBudgetItem[] = null;

  planAll: null | IReferencesPlanGetResponse[] = null;

  searchMotivation: IMotivationCalcAll | null = null;

  releaseMotivation: IMotivationRoiRow[] | null = null;

  releaseMotivationByUser: IMotivationCalcAll | null = null;

  isPageNotFound: boolean = false;

  allSearchMotivation: IMotivationSearchPricesResponse | null = null;

  percentForSales: IMotivationPercentForSalesResponse[] | null = null;

  dateValue: { year: number; month: number } | null = null;

  sizeAudience: null | IMotivationCoefsSizeAudienctResponse[] = null;

  // agreements table

  agreementsPage: number = 1;

  agreementsTotalCount: number = 0;

  agreementsInitSettingsConfirm: ISettingsSelected[] = [];
  agreementsInitSettingsSelect: ISettingsSelectedGroup[] = [];

  agreementSelectLists: ISettingsSelectedGroup[] = [];
  agreementConfirmLists: ISettingsSelected[] = [];

  agreementsCurDragItemParams: IDragParams | null = null;

  guaranteeNotCompleted: IGuaranteeNotCompletedItem[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSizeAudience = (sizeAudience: null | IMotivationCoefsSizeAudienctResponse[]) => {
    this.sizeAudience = sizeAudience;
  };

  changeSizeAudience = (id: number, value: number) => {
    if (this.sizeAudience) {
      this.sizeAudience = this.sizeAudience.map((item) => {
        if (item.id === id) {
          return { ...item, value };
        } else return item;
      });
    }
  };

  onChangeReleaseMotivationCell = (data: IMotivationCalcAll) => {
    if (this.releaseMotivationByUser) {
      this.releaseMotivationByUser = {
        ...this.releaseMotivationByUser,
        summaryTable: data.summaryTable,
      };
    }
  };

  onChangeSearchMotivationCell = (data: IMotivationCalcAll) => {
    if (this.searchMotivation) {
      this.searchMotivation = { ...this.searchMotivation, summaryTable: data.summaryTable };
    }
  };

  setDateValue(date: { year: number; month: number } | null): void {
    this.dateValue = date ? date : null;
  }

  setPercentForSales = (percentForSales: IMotivationPercentForSalesResponse[] | null) => {
    this.percentForSales = percentForSales;
  };

  onChaneFieldPercentForSales = (id: number, value: string) => {
    if (this.percentForSales) {
      this.percentForSales = this.percentForSales.map((item) => {
        if (item.id === id) {
          return { ...item, commission: value };
        } else {
          return item;
        }
      });
    }
  };

  setAllSearchMotivation = (allSearch: IMotivationSearchPricesResponse | null) => {
    this.allSearchMotivation = allSearch;
  };

  setIsPageNotFound(value: boolean) {
    this.isPageNotFound = value;
  }

  setPlanAll = (planAll: IReferencesPlanGetResponse[] | null) => {
    this.planAll = planAll;
  };

  setPlanGeneral = (value: number, field: string, cr: number, fact: number) => {
    if (this.planAll) {
      this.planAll = this.planAll.map((item) => {
        if (item.isGeneral === true) {
          if (fact !== -1) return { ...item, [field]: value, crFound: cr, crGiven: fact };

          return { ...item, [field]: value, crFound: cr };
        } else return item;
      });
    }
  };

  setPlanUser = (value: number, userId: number, field: string, cr: number, fact: number) => {
    if (this.planAll) {
      this.planAll = this.planAll.map((user) => {
        if (user.userId === userId) {
          return { ...user, [field]: value, crFound: cr, crGiven: fact };
        } else return user;
      });
    }
  };

  setReleaseMotivationByUser = (motivationsByUser: IMotivationCalcAll | null) => {
    this.releaseMotivationByUser = motivationsByUser;
  };

  deleteReleaseMotivationRoiRow = (id: number) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation?.filter((row) => row?.id !== id);
    }
  };

  setReleaseMotivation = (motivations: IMotivationRoiRow[]) => {
    this.releaseMotivation = motivations;
  };

  setCreateReleaseRoiThreshold = (rowId: number, roiThreshold: IMotivationThreshold) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return { ...row, thresholds: [...row.thresholds, roiThreshold] };
        } else return row;
      });
    }
  };

  setEditReleaseRoiThreshold = (
    rowId: number,
    cellId: number,
    roiThreshold: IMotivationThreshold
  ) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            thresholds: row.thresholds.map((cell) => {
              if (cell.id === cellId) {
                return roiThreshold;
              } else return cell;
            }),
          };
        } else return row;
      });
    }
  };

  setEditReleaseRoiThresholdBigChannel = (rowId: number, value: number) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            thresholdsBigChannels: [{ ...row.thresholdsBigChannels[0], value }],
          };
        } else return row;
      });
    }
  };

  setCreateReleaseCreateCoef = (rowId: number, coefThreshold: IMotivationRoiCoef) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return { ...row, roiCoefs: [coefThreshold, ...row.roiCoefs] };
        } else return row;
      });
    }
  };

  setEditReleaseCreateCoef = (rowId: number, cellId: number, coefThreshold: IMotivationRoiCoef) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            roiCoefs: row.roiCoefs.map((cell) => {
              if (cell.id === cellId) {
                return coefThreshold;
              } else return cell;
            }),
          };
        } else return row;
      });
    }
  };

  setEditReleaseRoiCoefsBigChannels = (
    rowId: number,
    cellId: number,
    coefThreshold: IMotivationRoiCoef
  ) => {
    if (this.releaseMotivation) {
      this.releaseMotivation = this.releaseMotivation.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            roiCoefsBigChannels: row.roiCoefsBigChannels.map((cell) => {
              if (cell.id === cellId) {
                return coefThreshold;
              } else return cell;
            }),
          };
        } else return row;
      });
    }
  };

  setBudgetAll = (budgetItems: IReferencesBudgetItem[]) => {
    this.budgetAll = budgetItems;
  };

  setGeneral = (value: number, field: string) => {
    if (this.budgetAll) {
      this.budgetAll = this.budgetAll.map((item) => {
        if (item.isGeneral === true) {
          return { ...item, [field]: value };
        } else return item;
      });
    }
  };

  setToUser = (userId: number, field: string, value: number) => {
    if (this.budgetAll) {
      this.budgetAll = this.budgetAll.map((user) => {
        if (user.userId === userId) {
          return { ...user, [field]: value };
        } else return user;
      });
    }
  };

  setSearchMotivation = (response: IMotivationCalcAll | null) => {
    this.searchMotivation = response;
  };

  addAgreementsAll = (agreements: IAgreementsResponseCreate[] | null) => {
    this.agreementsAll = agreements;
  };

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;
    if (this.agreementsAll) {
      this.agreementsAll = this.agreementsAll.map((item) => {
        if (item.integration.id === id) {
          const newItem = {
            ...item,
            integration: {
              ...item.integration,
              update_id,
              [field]: new_value,
            },
          };
          return newItem;
        } else return item;
      });
    }
  };

  setComment = (comment: string, agreementId: number, userId: number) => {
    if (this.agreementsAll) {
      this.agreementsAll = this.agreementsAll.map((agreement) => {
        if (agreement.id === agreementId) {
          return {
            ...agreement,
            approvments: agreement.approvments.map((userAgree) => {
              if (userAgree.user.id === userId) {
                return { ...userAgree, comment: comment };
              } else return userAgree;
            }),
          };
        } else return agreement;
      });
    }
  };

  changeAgreementStatus = (
    agreementId: number,
    userId: number,
    status: IStatusAgreementItem,
    statusCode: string,
    agreementsStatusCode: string,
    decisionDate: string | null,
    allDecisionDate: string | null
  ) => {
    if (this.agreementsAll) {
      this.agreementsAll = this.agreementsAll.map((agreement) => {
        if (agreement.id === agreementId) {
          return {
            ...agreement,
            allDecisionDate,
            agreementStatus: { ...agreement.agreementStatus, code: agreementsStatusCode },
            approvments: agreement.approvments.map((user) => {
              if (user.user.id === userId) {
                return {
                  ...user,
                  decisionDate,
                  approvedStatus: {
                    code: statusCode,
                    iconUrl: status.iconUrl,
                    name: status.name,
                  },
                };
              } else return user;
            }),
          };
        } else return agreement;
      });
    }
  };

  setSearchIntegrationId = (searchIntegrationId: number | null) => {
    this.searchIntegrationId = searchIntegrationId;
  };

  // agreement table store func

  setTotalCount(count: number) {
    this.agreementsTotalCount = count;
  }

  setAgreementsPage(page: number) {
    this.agreementsPage = page;
  }

  setInitSettingsSelect(array: ISettingsSelectedGroup[]) {
    this.agreementsInitSettingsSelect = array;
  }

  setInitSettingsConfirm(array: ISettingsSelected[]) {
    this.agreementsInitSettingsConfirm = array;
  }

  setSelectedList(array: ISettingsSelectedGroup[]) {
    this.agreementSelectLists = array;
  }

  setConfirmList(array: ISettingsSelected[]) {
    this.agreementConfirmLists = array;
  }

  setGuaranteeNotCompleted(array: IGuaranteeNotCompletedItem[] | null) {
    this.guaranteeNotCompleted = array;
  }

  setSelectedChecked(params: setCheckedParams) {
    const { code, group } = params;

    this.agreementSelectLists.map((groupArr) =>
      groupArr.group === group
        ? groupArr.fields.map((item) =>
            item.code === code ? (item.isSelected = !item.isSelected) : item
          )
        : groupArr
    );
  }

  addToConfirmList(item: ISettingsSelected) {
    const newArr: any[] = [
      ...this.agreementConfirmLists,
      { ...item, ordering: this.agreementConfirmLists.length + 1 },
    ];

    this.agreementConfirmLists = newArr;
  }

  removeConfirmList(id: string) {
    const newArr: any[] = [
      ...this.agreementConfirmLists
        .filter((item) => item.code !== id)
        .map((item, i) => {
          return { ...item, ordering: i + 1 };
        }),
    ];

    this.agreementConfirmLists = newArr;
  }

  onConfirmDelete(id: string) {
    this.agreementSelectLists.map((groupArr) =>
      groupArr.fields.map((item) =>
        item.code === id ? (item.isSelected = !item.isSelected) : item
      )
    );
  }

  onDragStart = (params: IDragParams) => {
    this.agreementsCurDragItemParams = params;
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent, params: IDragParams) => {
    e.preventDefault();

    if (
      params.code &&
      params.ordering &&
      this.agreementsCurDragItemParams?.code &&
      this.agreementsCurDragItemParams?.ordering &&
      params.code !== this.agreementsCurDragItemParams.code
    ) {
      const dragItem = { code: this.agreementsCurDragItemParams.code, ordering: params.ordering };
      const downItem = {
        params,
        code: params.code,
        ordering:
          this.agreementsCurDragItemParams.ordering < params.ordering
            ? params.ordering - 1
            : params.ordering + 1,
      };

      const afterDragArr: any[] = this.agreementConfirmLists.map((item) => {
        if (item.code === dragItem.code) {
          return { ...item, ...dragItem };
        } else if (item.code === downItem.code) {
          return { ...item, ...downItem };
        } else {
          return {
            ...item,
            ordering: item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1,
          };
        }
      });

      this.agreementConfirmLists = afterDragArr
        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
        .map((x, i) => {
          return { ...x, ordering: i + 1 };
        });
    }
  };
  sortReleaseMotivationByUserTableItem = (type: string, sortType: "asc" | "desc") => {
    if (
      type === "integration_date" &&
      this.releaseMotivationByUser &&
      this.releaseMotivationByUser.forRelease
    ) {
      const sortedReleases = [...this.releaseMotivationByUser.forRelease.motivationReleases].sort(
        (a, b) => {
          const dateA = new Date(a.integration?.integration_date ?? "");
          const dateB = new Date(b.integration?.integration_date ?? "");

          const timeA = dateA.getTime();
          const timeB = dateB.getTime();

          if (sortType === "asc") {
            return timeA - timeB;
          } else {
            return timeB - timeA;
          }
        }
      );

      this.releaseMotivationByUser.forRelease.motivationReleases = sortedReleases;
    }
  };

  sortSearchMotivationByUserTableItem = (type: string, sortType: "asc" | "desc") => {
    if (type === "integration_date" && this.searchMotivation && this.searchMotivation.forRelease) {
      const sortedReleases = [...this.searchMotivation.forRelease.motivationReleases].sort(
        (a, b) => {
          const dateA = new Date(a.integration?.integration_date ?? "");
          const dateB = new Date(b.integration?.integration_date ?? "");

          const timeA = dateA.getTime();
          const timeB = dateB.getTime();

          if (sortType === "asc") {
            return timeA - timeB;
          } else {
            return timeB - timeA;
          }
        }
      );

      this.searchMotivation.forRelease.motivationReleases = sortedReleases;
    }
  };

  sortAgreementTableItem = (type: string, sortType: "asc" | "desc") => {
    if (this.agreementsAll) {
      this.isLoadingSort = true;

      setTimeout(() => {
        if (type === "integration_date") {
          if (sortType === "desc") {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) =>
                a.integration.integration_date > b.integration.integration_date
                  ? 1
                  : b.integration.integration_date > a.integration.integration_date
                  ? -1
                  : 0
            );
          } else {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) =>
                a.integration.integration_date < b.integration.integration_date
                  ? 1
                  : b.integration.integration_date < a.integration.integration_date
                  ? -1
                  : 0
            );
          }
        } else if (type === "createdAt" || type === "allDecisionDate") {
          if (sortType === "desc") {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) => (a?.[type]! > b?.[type]! ? 1 : b?.[type]! > a?.[type]! ? -1 : 0)
            );
          } else {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) => (a?.[type]! < b?.[type]! ? 1 : b?.[type]! < a?.[type]! ? -1 : 0)
            );
          }
        } else {
          if (sortType === "desc") {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) =>
                a.integration.channel?.name?.toLowerCase() >
                b.integration.channel?.name?.toLowerCase()
                  ? 1
                  : b.integration.channel?.name?.toLowerCase() >
                    a.integration.channel?.name?.toLowerCase()
                  ? -1
                  : 0
            );
          } else {
            this.agreementsAll = [...(this.agreementsAll as IAgreementsResponseCreate[])].sort(
              (a, b) =>
                a.integration.channel?.name?.toLowerCase() <
                b.integration.channel?.name?.toLowerCase()
                  ? 1
                  : b.integration.channel?.name?.toLowerCase() <
                    a.integration.channel?.name?.toLowerCase()
                  ? -1
                  : 0
            );
          }
        }

        setTimeout(() => {
          this.isLoadingSort = false;
        }, 0);
      }, 0);
    }
  };
}

export default new DashboardStore();
