import { FC } from "react";
import { IIntegrationMetric } from "./IIntegrationMetric";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { AsideCardChart } from "../../ui/AsideCardChart/AsideCardChart";
import { SkeletonChart } from "../../ui/SkeletonChart/SkeletonChart";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { AsideOrdersQuanity } from "../../ui/AsideOrdersQuanity/AsideOrdersQuanity";
import { AsideMetricChart } from "../../ui/AsideMetricChart/AsideMetricChart";
import { AsideTableTraficStatistic } from "../../ui/AsideTableTraficStatistic/AsideTableTraficStatistic";

export const IntegrationMetric: FC<IIntegrationMetric> = (props) => {
  const {
    id,
    closeModal,
    dataOrder,
    isOrdersLoading,
    cac,
    integrationDate,
    source,
    dataOnSource,
    isMetricError,
    isMetricLoading,
    isClone,
    isPromoForCloneChanged,
  } = props;

  return (
    <Box
      sx={{
        display: { lg: "grid", md: "block" },
        gridTemplateColumns: { lg: "2fr 1fr", xs: "1fr" },
        columnGap: 2,
        height: "100%",
      }}>
      {isClone && !isPromoForCloneChanged ? (
        <Stack>
          <AsideCardLayout>
            <Typography
              variant="h6"
              color={"error"}
              sx={{ fontWeight: "normal", fontStyle: "italic", fontSize: "0.9rem" }}>
              Метирку смотрите в интеграции, от которой был создан Перевыпуск по гаранту
            </Typography>
          </AsideCardLayout>
        </Stack>
      ) : (
        <Stack spacing={2}>
          {!isOrdersLoading && dataOrder === null && (
            <AsideCardLayout>
              <Typography variant="h6" sx={{ fontStyle: "italic", fontSize: "0.9rem" }}>
                Заказов ещё не было
              </Typography>
            </AsideCardLayout>
          )}
          {dataOrder !== null && cac !== null && dataOrder.orders.length > 0 ? (
            <AsideOrdersQuanity dataOrder={dataOrder} price={cac.toString()} />
          ) : null}
          {isOrdersLoading && <SkeletonChart />}
          {!isOrdersLoading && dataOrder && (
            <AsideCardLayout>
              <Stack direction={"column"}>
                {!isOrdersLoading && dataOrder ? (
                  <Stack spacing={1}>
                    <Typography variant="h5">Динамика заказов</Typography>
                    {dataOrder.orders.length > 0 && <AsideCardChart data={dataOrder.orders} />}
                  </Stack>
                ) : null}
                {!isOrdersLoading && dataOrder?.orders.length === 0 ? (
                  <Typography
                    variant="h6"
                    color={"grey"}
                    sx={{ fontWeight: "normal", mt: 0.5, fontStyle: "italic", fontSize: "0.9rem" }}>
                    Заказов ещё не было
                  </Typography>
                ) : null}
              </Stack>
            </AsideCardLayout>
          )}
          <AsideCardLayout>
            {!isMetricLoading && dataOnSource && (
              <AsideTableTraficStatistic dataOnSource={dataOnSource} />
            )}
            {isMetricLoading && <Skeleton variant="rounded" height={48} width={"100%"} />}
            {!isMetricLoading && dataOnSource === null && (
              <Typography variant="h6">Динамики по трафику нет</Typography>
            )}
            {isMetricError && (
              <Typography
                variant="h6"
                color={"error"}
                sx={{ fontWeight: "normal", fontStyle: "italic", fontSize: "0.9rem" }}>
                {isMetricError}
              </Typography>
            )}
          </AsideCardLayout>
          {source && (
            <AsideMetricChart
              source={source}
              initDate={integrationDate}
              todayDate={new Date().toISOString()}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};
