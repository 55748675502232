import { FC, useState, useEffect } from "react";
import { IReleaseItemComponent } from "../models/IReleaseItem";
import { Avatar, Box, Link, MenuItem, Stack, Typography } from "@mui/material";
import {
  BorderTop,
  CalendarMonth,
  Comment,
  ContentCopy,
  Favorite,
  Update,
  Visibility,
} from "@mui/icons-material";
import channelStore from "../../../store/channel.store";
import { ReleaseModalEdit } from "./ReleaseModalEdit";
import { AlertModal } from "../../AlertModal/AlertModal";
import { ReleaseSelectPlacement } from "./ReleaseSelectPlacement";
import { IReleasesRequestEdit } from "../../../services/releases/IReleasesRequest";
import { getDatePlusCustomTime } from "../../../shared/functions/functions";
import { RemoveItemButton } from "../../UI/Buttons/RemoveItemButton/RemoveItemButton";
import { EditIconButton } from "../../UI/Buttons/EditIconButton/EditIconButton";
import { CustomIconButton } from "../../UI/Buttons/CustomIconButton/CustomIconButton";

export const ReleaseItem: FC<IReleaseItemComponent> = (props) => {
  const {
    id,
    comments,
    likes,
    link,
    releaseDate,
    type,
    views,
    fullWidth = false,
    onClick,
    onCopyText,
    wrap = false,
    minWidth,
    onDelete,
    isEditMode,
    onEdit,
    parentId,
    error,
    loading,
    readOnly = false,
    integrationPlace,
    onChangePlacement,
    onCloseModalEdit,
    onClosePlacement,
    lastUpdate,
    alertErrorText,
    closeErrorAlert,
    errorShowAlert,
    isMain,
    isDefault,
    separator
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEdit, setEdit] = useState(false);
  const [isShowDeleteAlert, setShowDeleteAlert] = useState(false);

  const remove = () => {
    if (onDelete && id) {
      onDelete(id);
    }
  };

  const edit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setEdit(true);
  };

  const handelDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteAlert(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editPlacement = (code: string) => {
    const item: IReleasesRequestEdit = {
      id: id!,
      comments,
      likes,
      link,
      releaseDate,
      default: isDefault,
      type: type.code,
      views,
      integrationPlace: code,
    };
    onChangePlacement(item, code);
  };

  useEffect(() => {
    if (onClosePlacement) {
      handleClose();
    }
  }, [onClosePlacement]);

  useEffect(() => {
    if (onCloseModalEdit) {
      setEdit(false);
    }
  }, [onCloseModalEdit]);

  return (
    <>
      <MenuItem
        onClick={onClick}
        sx={{
          pl: 1,
          display: "flex",
          flexDirection: wrap ? "column" : "row",
          alignItems: wrap ? "flex-start" : "center",
          borderLeft: isMain ? "2px solid black" : "",
          ...(separator && { borderTop: "2px dotted red" }),
        }}>
          {!isDefault && <Typography color={'#d32f2f'}>перевыпуск</Typography>}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!readOnly && onDelete && <RemoveItemButton onClick={handelDeleteClick} />}
          {!readOnly && isEditMode && <EditIconButton onClick={edit} size="small" />}
          <CustomIconButton sx={{ mr: 0.5 }} onClick={onCopyText} size="small">
            <ContentCopy fontSize="small" />
          </CustomIconButton>
          <Avatar
            src={channelStore?.channels?.[type?.code]?.icon || ""}
            alt={type?.code}
            sx={{ height: 20, width: 20, mr: 1 }}
          />
          <Link
            color={"secondary"}
            href={link}
            target="_blank"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: fullWidth ? "100%" : minWidth ? minWidth : "200px",
            }}>
            {link}
          </Link>
          <Box sx={{ ml: 1 }} onClick={(e) => e.stopPropagation()}>
            <ReleaseSelectPlacement
              code={integrationPlace?.code || ""}
              onChange={editPlacement}
              anchorEl={anchorEl}
              onClose={handleClose}
              onOpen={handleClick}
              readOnly={readOnly}
            />
          </Box>
        </Box>
        <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Visibility color="action" sx={{ mr: 0.5 }} />
            {views}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Favorite color="error" sx={{ mr: 0.5 }} />
            {likes}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Comment color="disabled" sx={{ mr: 0.5 }} />
            {comments}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarMonth color="action" sx={{ mr: 0.5 }} />
            <Typography sx={{ fontWeight: isMain ? "bold" : "" }}>
              {releaseDate
                ? getDatePlusCustomTime({ value: releaseDate, format: "DD.MM.YY HH:mm" })
                : "—"}
            </Typography>
          </Box>
          {lastUpdate && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Update color="action" sx={{ mr: 0.5 }} />
              <Typography sx={{ fontWeight: isMain ? "bold" : "" }}>
                {getDatePlusCustomTime({ value: lastUpdate, format: "DD.MM.YY HH:mm" })}
              </Typography>
            </Box>
          )}
        </Stack>
      </MenuItem>
      {isEdit && id && (
        <ReleaseModalEdit
          releaseId={id}
          data={props}
          open={isEdit}
          itemId={parentId}
          onClose={() => setEdit(false)}
          onSubmit={onEdit}
          error={error}
          loading={loading}
          alertErrorText={alertErrorText}
          closeErrorAlert={closeErrorAlert}
          errorShowAlert={errorShowAlert}
        />
      )}
      {isShowDeleteAlert && (
        <AlertModal
          title="Вы действительно хотите удалить релиз?"
          open={isShowDeleteAlert}
          onClose={() => setShowDeleteAlert(false)}
          onSubmit={remove}
          buttonCancelName="Отмена"
          buttonSubmitName="Удалить"
          colorSubmit="error"
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
