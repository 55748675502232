import { FC } from 'react'
import { Box, Tab, Tabs } from "@mui/material"
import { IAsideTAbs } from './IAsideTabs'

export const AsideTabs:FC<IAsideTAbs> = (props) => {
  const {
    initValue, 
    setValue, 
    tabs, 
    width = '100%', 
    sx,
    allowScrollButtonsMobile = true
  } = props

  const handleChange = (event: React.SyntheticEvent, newValue: number | string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{width, ...sx}}>
      <Tabs
        value={initValue}
        onChange={handleChange}
        allowScrollButtonsMobile={allowScrollButtonsMobile}
        variant={allowScrollButtonsMobile ? 'scrollable' : 'standard'}
        sx={{
          '& .MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
        }}
      >
        {tabs.map(tab=>{
          if(tab?.show !== false){
            return <Tab key={tab.value} value={tab.value} label={tab.name} disabled={tab.disabled}/>
          } else return null
        })}
      </Tabs>
    </Box>
  )
}