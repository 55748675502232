import { useCallback, useRef } from "react";

export const useDebounce = <T extends (...args: any[]) => void>(callback: T, interval: number) => {
  const prevTimeOutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...args: Parameters<T>) => {
      if (prevTimeOutRef.current) {
        clearTimeout(prevTimeOutRef.current); 
      }

      prevTimeOutRef.current = setTimeout(() => {
        callback(...args); 
      }, interval);
    },
    [callback, interval]
  );
};
