import { IFromToArray } from "../../../services/integration/IntegrationsRequest";
import { IPaymentsFilterQuery } from "../../../services/payments/IPaymentsRequest";
import {
  IReferencesPaymentTypesResponse,
  IRequisteAccount,
} from "../../../services/references/IReferencesResponse";
import { IFilterSelectItem } from "../../Filters/UI/FilterSelectChip/IFilterSelectChip";
import {
  ISettingsFilters,
  IStatusesChannel,
  IStatusesPayments,
} from "../../Statuses/models/ISettingsFilters";
import { toJS } from "mobx";

export const getPaymentFilterHotSlots = (): IFilterSelectItem[] => {
  const hotSlots: IFilterSelectItem[] = [
    { name: "Да", value: "true" },
    { name: "Нет", value: "false" },
  ];

  return hotSlots;
};

export const getPaymentType = (data: IReferencesPaymentTypesResponse[]): IFilterSelectItem[] => {
  return data.map((item) => {
    return {
      name: item.name,
      value: item.code,
    };
  });
};

export const getTypeChanell = (): IFilterSelectItem[] => {
  const types: IFilterSelectItem[] = [
    { name: "Новый", value: "true" },
    { name: "Старый", value: "false" },
  ];

  return types;
};

export const getIsVeifiedByGovStatuses = (): IFilterSelectItem[] => {
  const statuses: IFilterSelectItem[] = [
    { name: "Да", value: "yes" },
    { name: "Нет", value: "no" },
    { name: "Заявка подана", value: "wait" },
  ];

  return statuses;
};

export const getPaymentFilterStatuses = (
  allStatuses: IStatusesPayments | ISettingsFilters | IStatusesChannel
): IFilterSelectItem[] => {
  const statuses: IFilterSelectItem[] = Object.keys(allStatuses).map((keys) => {
    const newItem: IFilterSelectItem = {
      name: allStatuses[keys].name,
      value: keys,
    };
    return newItem;
  });

  return statuses;
};

export const getPaymentFilterCompanyAccount = (
  companyAccount: IRequisteAccount[]
): IFilterSelectItem[] => {
  const accounts: IFilterSelectItem[] = companyAccount.map((x) => {
    const item: IFilterSelectItem = {
      name: x.name,
      value: x.code,
    };
    return item;
  });

  return accounts;
};

export const getFilterPaymentsQuery = (searchQuery: URLSearchParams): IPaymentsFilterQuery => {
  const transormValueToArrayFromTo = (
    searchArr: string[],
    isDate?: boolean
  ): IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map((x) => +x) : searchArr;

    const fromToArray: IFromToArray<number | string> = {
      from: array[0],
      to: array[1],
    };
    return fromToArray;
  };

  const option: IPaymentsFilterQuery | any = {
    id: searchQuery.get("id")?.split(",") || null,
    counteragentId:
      searchQuery
        .get("payment_counteragent_Id")
        ?.split(",")
        .map((x) => +x) || null,
    payStatus: searchQuery.get("payment_pay_status")?.split(",") || null,
    edo: searchQuery.get("payment_counteragent_edo")
      ? searchQuery.get("payment_counteragent_edo") === "true"
      : null,
    inn: searchQuery.get("payment_counteragent_inn")?.split(",") || undefined,
    counteragent: searchQuery.get("payment_counteragent_shortName")?.split(",") || undefined,
    operator: searchQuery.get("payment_counteragent_operator")?.split(",") || undefined,
    counteragentType: searchQuery.get("counteragentType")?.split(",") || undefined,
    amount: searchQuery.get("amount")
      ? transormValueToArrayFromTo(searchQuery.get("amount")?.split(",")!)
      : null,
    commission: searchQuery.get("commission")
      ? transormValueToArrayFromTo(searchQuery.get("commission")?.split(",")!)
      : null,
    paidSumm: searchQuery.get("paidSumm")
      ? transormValueToArrayFromTo(searchQuery.get("paidSumm")?.split(",")!)
      : null,
    totalSumm: searchQuery.get("totalSumm")
      ? transormValueToArrayFromTo(searchQuery.get("totalSumm")?.split(",")!)
      : null,
    hotSlot: searchQuery.get("hotSlot") ? searchQuery.get("hotSlot") === "true" : null,
    // status:searchQuery.get('status')?.split(',') || null,
    paymentType: searchQuery.get("payment_paymentType")?.split(",") || null,
    paymentStatus: searchQuery.get("paymentStatus")?.split(",") || null,
    channel: searchQuery.get("channel")
      ? JSON.parse(searchQuery.get("channel")!).map((x: IFilterSelectItem) => x.value)
      : null,
    createdAt: searchQuery.get("createdAt")
      ? transormValueToArrayFromTo(searchQuery.get("createdAt")?.split(",")!, true)
      : null,
    plannedPaidAt: searchQuery.get("plannedPaidAt")
      ? transormValueToArrayFromTo(searchQuery.get("plannedPaidAt")?.split(",")!, true)
      : null,
    companyAccount: searchQuery.get("companyAccount")?.split(",") || null,
    releaseUserId: searchQuery.get("payment_releaseUser")?.split(",") || null,
    hasAct: searchQuery.get("payment_hasAct") ? searchQuery.get("payment_hasAct") === "true" : null,
    hasSelfEmployedCheck: searchQuery.get("payment_selfEmployedCheckLink")
      ? searchQuery.get("payment_selfEmployedCheckLink") === "true"
      : null,
    isServiceProvided: searchQuery.get("payment_isServiceProvided")
      ? searchQuery.get("payment_isServiceProvided") === "true"
      : null,
    isOriginalsReceived: searchQuery.get("payment_contractBlock_isOriginalsReceived")
      ? searchQuery.get("payment_contractBlock_isOriginalsReceived") === "true"
      : null,
    payTotalSumm: searchQuery.get("payTotalSumm")
      ? transormValueToArrayFromTo(searchQuery.get("totalSumm")?.split(",")!)
      : null,
    integrationId:
      searchQuery
        .get("integrationId")
        ?.split(",")
        .map((x) => +x) || null,
    channelId: searchQuery.get("channelId")
      ? JSON.parse(searchQuery.get("channelId")!).map((x: IFilterSelectItem) => x.value)
      : null,
    promokod: searchQuery.get("promokod"),
    integration_date: searchQuery.get("integration_date")
      ? transormValueToArrayFromTo(searchQuery.get("integration_date")?.split(",")!, true)
      : null,
    integrationPrice: searchQuery.get("integrationPrice")
      ? transormValueToArrayFromTo(searchQuery.get("integrationPrice")?.split(",")!)
      : null,
    paidAt: searchQuery.get("paidAt")
      ? transormValueToArrayFromTo(searchQuery.get("paidAt")?.split(",")!, true)
      : null,
    payPlannedPaidAt: searchQuery.get("payPlannedPaidAt")
      ? transormValueToArrayFromTo(searchQuery.get("payPlannedPaidAt")?.split(",")!, true)
      : null,
    payPaidAt: searchQuery.get("payPaidAt")
      ? transormValueToArrayFromTo(searchQuery.get("payPaidAt")?.split(",")!, true)
      : null,
    ord_hasAct: searchQuery.get("ord_hasAct") ? searchQuery.get("ord_hasAct") === "true" : null,
    payDirection: searchQuery.get("payment_pay_direction")
      ? JSON.parse(searchQuery.get("payment_pay_direction")!).map((x: IFilterSelectItem) => x.value)
      : null,
    isVerifiedByGov: searchQuery.get("isVerifiedByGov")
  };

  const filterEmptyFieldOption: any = {};

  for (let key in option) {
    if (option[key] || option[key] === false) {
      filterEmptyFieldOption[key] = option[key];
    }
  }

  return filterEmptyFieldOption;
};

export const createTableData = (items: any[], item: any, pushInFirst?: boolean) => {
  const pays_id: any[] = [];
  const pays_totalSumm: any[] = [];
  const status_pay: any[] = [];
  const pay_fio: any[] = [];
  const pay_card: any[] = [];
  const pay_plannedPaidAt: any[] = [];
  const pay_selfEmployedCheck: any[] = [];
  const pay_link: any[] = [];
  const pay_source: any[] = [];
  const pay_paidAt: any[] = [];
  const pay_direction: any[] = [];
  const integration_date: any[] = [];
  const integration_id: any[] = [];
  const integration_price: any[] = [];
  const firstIntegrationWithChannel: any[] = [];
  const integration_status: any[] = [];
  const integration_promokod: any[] = [];
  const integration_channel: any[] = [];
  const channel_contact: any[] = [];
  const pay_paymentType: any[] = [];
  const ordInfo_hasAct: any[] = [];
  const ordInfo_token: any[] = [];
  toJS(item.ordInfo)?.map((ord: any) => ordInfo_token.push(ord.token));
  toJS(item.ordInfo)?.map((ord: any) => ordInfo_hasAct.push(ord.hasAct ? "Есть" : "Нет"));
  toJS(item.pays)?.map((pay: any) => pays_id.push(pay.id));
  toJS(item.pays)?.map((pay: any) => pay_paidAt.push(pay.paidAt));
  toJS(item.pays)?.map((pay: any) => pays_totalSumm.push(pay.totalSumm));
  toJS(item.pays)?.map((pay: any) => status_pay.push(toJS(pay.status)));
  toJS(item.pays)?.map((pay: any) => pay_fio.push(pay.fio));
  toJS(item.pays)?.map((pay: any) => pay_card.push(pay.phoneOrCard));
  toJS(item.pays)?.map((pay: any) => pay_plannedPaidAt.push(pay.plannedPaidAt));
  toJS(item.pays)?.map((pay: any) =>
    pay_selfEmployedCheck.push(pay.selfEmployedCheck ? "Есть" : "Нет")
  );
  toJS(item.pays)?.map((pay: any) => pay_link.push(pay.paymentLink));
  toJS(item.pays)?.map((pay: any) => pay_source.push(pay.source?.name));
  toJS(item.pays)?.map((pay: any) =>
    pay_direction.push(
      pay.paymentType?.code === "bank" ? pay.direction?.shortName : pay.direction?.name
    )
  );
  toJS(item.pays)?.map((pay: any) => pay_paymentType.push(pay.paymentType?.name));
  toJS(item.integrations)?.map((integration: any) => integration_price.push(integration.price));
  toJS(item.integrations)?.map((integration: any) =>
    firstIntegrationWithChannel.push(integration.firstIntegrationWithChannel ? "Да" : "Нет")
  );
  toJS(item.integrations)?.map((integration: any) => integration_id.push(integration.id));
  toJS(item.integrations)?.map((integration: any) =>
    integration_date.push(integration.integration_date)
  );
  toJS(item.integrations)?.map((integration: any) =>
    integration_status.push(toJS(integration.status))
  );
  toJS(item.integrations)?.map((integration: any) =>
    integration_promokod.push(integration.promokod)
  );
  toJS(item.integrations)?.map((integration: any) => integration_channel.push(integration.channel));
  toJS(item.integrations)?.map((integration: any) =>
    channel_contact.push(integration.channel?.contact)
  );

  const commonItem = {
    payment_counteragent_Id: item.counteragent?.id,
    payment_counteragent_edo: item.counteragent?.edo === true ? "Есть" : "Нет",
    payment_counteragent_inn: item.counteragent?.inn,
    payment_counteragent_operator: item.counteragent?.operator?.name,
    payment_counteragent_shortName: item.counteragent?.shortName,
    payment_hasAct: item.hasAct === true ? "Есть" : "Нет",
    payment_isServiceProvided: item.isServiceProvided === true ? "Да" : "Нет",
    payment_createdAt: item.createdAt,
    payment_releaseUser: item.releaseUser,
    payment_contractSum: item.contract?.summ,
    payment_contractBlock_summAttachment: item.attachment?.serviceCost,
    payment_contractBlock_summ: item.contract?.summ,
    payment_contractBlock_isOriginalsReceived: item.isOriginalsReceived ? "Да" : "Нет",
    payment_contractBlock_trackNumber: item.trackNumber,
    payment_contractBlock_contract_link: item.contract,
    payment_contractBlock_attachment_link: item.attachment,
    payment_pay_id: pays_id,
    payment_pay_totalSumm: pays_totalSumm,
    payment_pay_status: status_pay,
    payment_pay_fio: pay_fio,
    payment_pay_phoneOrCard: pay_card,
    payment_pay_plannedPaidAt: pay_plannedPaidAt,
    payment_pay_selfEmployedCheck: pay_selfEmployedCheck,
    payment_pay_paymentLink: pay_link,
    payment_pay_source: pay_source,
    payment_pay_direction: pay_direction,
    payment_integration_date: integration_date,
    payment_integration_id: integration_id,
    payment_integration_price: integration_price,
    payment_integration_firstIntegrationWithChannel: firstIntegrationWithChannel,
    payment_integration_status: integration_status,
    payment_integration_promokod: integration_promokod,
    payment_integration_channel: integration_channel,
    payment_channel_contact: channel_contact,
    payment_pay_paymentType: pay_paymentType,
    payment_pay_paidAt: pay_paidAt,
    ordInfo_hasAct: ordInfo_hasAct,
    ordInfo_token: ordInfo_token,
    ...item,
  };

  if (pushInFirst) {
    items.unshift(commonItem);
  } else {
    items.push(commonItem);
  }
};

export const transformPaymentItem = (item: any): any => {
  const pays_id: any[] = [];
  const pays_totalSumm: any[] = [];
  const status_pay: any[] = [];
  const pay_fio: any[] = [];
  const pay_card: any[] = [];
  const pay_plannedPaidAt: any[] = [];
  const pay_selfEmployedCheck: any[] = [];
  const pay_link: any[] = [];
  const pay_source: any[] = [];
  const pay_paidAt: any[] = [];
  const pay_direction: any[] = [];
  const integration_date: any[] = [];
  const integration_id: any[] = [];
  const integration_price: any[] = [];
  const firstIntegrationWithChannel: any[] = [];
  const integration_status: any[] = [];
  const integration_promokod: any[] = [];
  const integration_channel: any[] = [];
  const channel_contact: any[] = [];
  const pay_paymentType: any[] = [];
  const ordInfo_hasAct: any[] = [];
  const ordInfo_token: any[] = [];
  item.ordInfo?.map((ord: any) => ordInfo_token.push(ord.token));
  item.ordInfo?.map((ord: any) => ordInfo_hasAct.push(ord.hasAct ? "Есть" : "Нет"));
  item.pays?.map((pay: any) => pays_id.push(pay.id));
  item.pays?.map((pay: any) => pay_paidAt.push(pay.paidAt));
  item.pays?.map((pay: any) => pays_totalSumm.push(pay.totalSumm));
  item.pays?.map((pay: any) => status_pay.push(pay.status));
  item.pays?.map((pay: any) => pay_fio.push(pay.fio));
  item.pays?.map((pay: any) => pay_card.push(pay.phoneOrCard));
  item.pays?.map((pay: any) => pay_plannedPaidAt.push(pay.plannedPaidAt));
  item.pays?.map((pay: any) => pay_selfEmployedCheck.push(pay.selfEmployedCheck ? "Есть" : "Нет"));
  item.pays?.map((pay: any) => pay_link.push(pay.paymentLink));
  item.pays?.map((pay: any) => pay_source.push(pay.source?.name));
  item.pays?.map((pay: any) =>
    pay_direction.push(
      pay.paymentType?.code === "bank" ? pay.direction?.shortName : pay.direction?.name
    )
  );
  item.pays?.map((pay: any) => pay_paymentType.push(pay.paymentType?.name));
  item.integrations?.map((integration: any) => integration_price.push(integration.price));
  item.integrations?.map((integration: any) =>
    firstIntegrationWithChannel.push(integration.firstIntegrationWithChannel ? "Да" : "Нет")
  );
  item.integrations?.map((integration: any) => integration_id.push(integration.id));
  item.integrations?.map((integration: any) => integration_date.push(integration.integration_date));
  item.integrations?.map((integration: any) => integration_status.push(integration.status));
  item.integrations?.map((integration: any) => integration_promokod.push(integration.promokod));
  item.integrations?.map((integration: any) => integration_channel.push(integration.channel));
  item.integrations?.map((integration: any) => channel_contact.push(integration.channel?.contact));

  return {
    payment_counteragent_Id: item.counteragent?.id,
    payment_counteragent_edo: item.counteragent?.edo === true ? "Есть" : "Нет",
    payment_counteragent_inn: item.counteragent?.inn,
    payment_counteragent_operator: item.counteragent?.operator?.name,
    payment_counteragent_shortName: item.counteragent?.shortName,
    payment_hasAct: item.hasAct === true ? "Есть" : "Нет",
    payment_isServiceProvided: item.isServiceProvided === true ? "Да" : "Нет",
    payment_createdAt: item.createdAt,
    payment_releaseUser: item.releaseUser,
    payment_contractSum: item.contract?.summ,
    payment_contractBlock_summAttachment: item.attachment?.serviceCost,
    payment_contractBlock_summ: item.contract?.summ,
    payment_contractBlock_isOriginalsReceived: item.isOriginalsReceived ? "Да" : "Нет",
    payment_contractBlock_trackNumber: item.trackNumber,
    payment_contractBlock_contract_link: item.contract,
    payment_contractBlock_attachment_link: item.attachment,
    payment_pay_id: pays_id,
    payment_pay_totalSumm: pays_totalSumm,
    payment_pay_status: status_pay,
    payment_pay_fio: pay_fio,
    payment_pay_phoneOrCard: pay_card,
    payment_pay_plannedPaidAt: pay_plannedPaidAt,
    payment_pay_selfEmployedCheck: pay_selfEmployedCheck,
    payment_pay_paymentLink: pay_link,
    payment_pay_source: pay_source,
    payment_pay_direction: pay_direction,
    payment_integration_date: integration_date,
    payment_integration_id: integration_id,
    payment_integration_price: integration_price,
    payment_integration_firstIntegrationWithChannel: firstIntegrationWithChannel,
    payment_integration_status: integration_status,
    payment_integration_promokod: integration_promokod,
    payment_integration_channel: integration_channel,
    payment_channel_contact: channel_contact,
    payment_pay_paymentType: pay_paymentType,
    payment_pay_paidAt: pay_paidAt,
    ordInfo_hasAct: ordInfo_hasAct,
    ordInfo_token: ordInfo_token,
    ...item,
  };
};
