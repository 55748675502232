import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IMotivatioEditForManagerORFine, IMotivationChangeGoodsCategoryRequest, IMotivationCoefsRequest, IMotivationEditSearchPercentRequest, IMotivationEditSearchPricesRequest, IMotivationExcelForSearchRequest, IMotivationForManagerRequest, IMotivationRoiEditRequest, IMotivationSearchPricesRequest, IMotivationSearchRequest, } from "./IMotivationRequest";
import { IGuaranteeNotCompletedItem, IMotivationCalcAll, IMotivationCoefsResponse, IMotivationPercentForSalesResponse, IMotivationRoiCoef, IMotivationSearchPriceResponse, IMotivationSearchPricesResponse, IMotivationThreshold } from "./IMotivationResponse";

export default class MotivationService {

  static getNewMotivationCoefs(option?:IMotivationCoefsRequest):Promise<AxiosResponse<IMotivationCoefsResponse>>{
    return $gateway_api.get('/api/motivation/coefs', {
      params:option
    })
  }

  static edit(option:IMotivationRoiEditRequest):Promise<AxiosResponse<IMotivationRoiCoef | IMotivationThreshold>>{
    return $gateway_api.post('/api/motivation/roi/edit', option)
  }

  static searchPrices(params?:IMotivationSearchPricesRequest):Promise<AxiosResponse<IMotivationSearchPricesResponse>>{
    return $gateway_api.get('/api/motivation/searchPrices', {
      params
    })
  }

  static motivationCalcAll(params:IMotivationSearchRequest):Promise<AxiosResponse<IMotivationCalcAll>>{
    return $gateway_api.get('/api/motivation/calc/all', {
      params
    })
  }
  
  static editSearchPrices(option:IMotivationEditSearchPricesRequest):Promise<AxiosResponse<IMotivationSearchPriceResponse>>{
    return $gateway_api.post('/api/motivation/search/editSearchPrices', option)
  }

  static editSearchPercent(option:IMotivationEditSearchPercentRequest):Promise<AxiosResponse<IMotivationPercentForSalesResponse>>{
    return $gateway_api.post('/api/motivation/search/editSearchPercent', option)
  }

  static percentForSales(params?:IMotivationSearchPricesRequest):Promise<AxiosResponse<IMotivationPercentForSalesResponse[]>>{
    return $gateway_api.get('/api/motivation/percentForSales', {
      params
    })
  }

  static addGoodsCategoryInMotivation(option:IMotivationChangeGoodsCategoryRequest): Promise<AxiosResponse<IMotivationCoefsResponse>>{
    return $gateway_api.post('/api/motivation/addGoodsCategoryInMotivation', option)
  }

  static deleteGoodsCategoryFromMotivation(option:IMotivationChangeGoodsCategoryRequest): Promise<AxiosResponse<IMotivationCoefsResponse>>{
    return $gateway_api.post('/api/motivation/deleteGoodsCategoryFromMotivation', option)
  }

  static editMotivationForManager(option:IMotivationForManagerRequest): Promise<AxiosResponse<IMotivationCalcAll>>{
    return $gateway_api.post('/api/motivation/editMotivationForManager', option)
  }

  static editMotivationForManagerORFine(option:IMotivatioEditForManagerORFine): Promise<AxiosResponse<IMotivationCalcAll>>{
    return $gateway_api.post('/api/motivation/editMotivationForManagerORFine', option)
  }

  static motivationExcelForSearch(option:IMotivationExcelForSearchRequest, onDownloadProgress?:((progressEvent: AxiosProgressEvent)=>void)):Promise<AxiosResponse<Blob>>{
    return $gateway_api.get('/api/motivation/excelForSearch', {
      responseType:'blob',
      onDownloadProgress,
      params:option
    })
  }

  static getAllGuaranteeNotCompleted(): Promise<AxiosResponse<IGuaranteeNotCompletedItem[]>> {
    return $gateway_api.get('api/motivation/calcByAllReleaseUsers')
  }
  
}