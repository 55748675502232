import { makeAutoObservable } from "mobx";
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup";
import { IDragParams, setCheckedParams } from "./integrationTable.store";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";
import { IChannelTheame } from "../entities/ChannelTheame/models/IChannelTheame";
import {
  IChannelFullResponse,
  IChannelGovVerificationResponse,
} from "../services/channel/ICnannelResponse";
import { IPostedCommentItem } from "../components/PostedCommentItem/models/IPostedCommentItem";
import { IChannelAudienceRequest } from "../pages/ChannelCardPage/ui/ChannelAudience/shared/dataList";
import { IRenderFilterLists } from "../components/Filters/interfaces/IRenderFilterItem";
import { LS_FILTERS_ALL_CHANNEL, LS_FILTERS_CHANNEL } from "../shared/constants/localstorage";

import allFilterListJson from "../shared/mock/filters.mock.json";

class ChannelTableStore {
  page: number = 1;

  isLoading: boolean = true;

  isLoadingSort: boolean = true;

  totalCount: number = 0;

  tableItems: any[] = [];

  allFilter: string[] = [];

  isPageNotFound: boolean = false;

  initSettingsConfirm: ISettingsSelected[] = [];
  initSettingsSelect: ISettingsSelectedGroup[] = [];

  selectLists: ISettingsSelectedGroup[] = [];
  confirmLists: ISettingsSelected[] = [];

  curDragItemParams: IDragParams | null = null;

  showSuccsecDeleted: boolean | null = null;

  channelFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_CHANNEL)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_CHANNEL)!)
    : allFilterListJson.channelAllFilters;

  channelAllFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_ALL_CHANNEL)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_ALL_CHANNEL)!)
    : allFilterListJson.channelAllFilters;

  channelPresetFilters: IRenderFilterLists[] = [];
  channelAllPresetFilters: IRenderFilterLists[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setChanneAllPresetFilters = (channelAllPresetFilters: IRenderFilterLists[]) => {
    this.channelAllPresetFilters = channelAllPresetFilters;
  };

  setChannelPresetFilters = (channelPresetFilters: IRenderFilterLists[]) => {
    this.channelPresetFilters = channelPresetFilters;
  };

  setChannelFilters = (channelFilters: IRenderFilterLists[]) => {
    this.channelFilters = channelFilters;
  };

  setChannelAllFilters = (channelAllFilters: IRenderFilterLists[]) => {
    this.channelAllFilters = channelAllFilters;
  };

  addChannel(item: any) {
    this.tableItems.unshift(item);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setPage(page: number) {
    this.page = page;
  }

  setAllFilter(array: string[]) {
    this.allFilter = array;
  }

  setTableItems(items: any[]) {
    this.tableItems = items;
  }

  setIsPageNotFound(value: boolean) {
    this.isPageNotFound = value;
  }

  addReleaseByManager = (id: number, field: string, update_id: number, value: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: { id: value },
        };
        return newItem;
      }
      return item;
    });
  };

  removeReleaseByManager = (id: number, field: string, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: null,
        };
        return newItem;
      }
      return item;
    });
  };

  setGovVerification = (channelId: number, govVerification: IChannelGovVerificationResponse) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === channelId) {
        const newItem = {
          ...item,
          govVerification,
        };
        return newItem;
      }
      return item;
    });
  };

  changeUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: { code: type },
        };
        return newItem;
      }
      return item;
    });
  };

  setInitSettingsSelect(array: ISettingsSelectedGroup[]) {
    this.initSettingsSelect = array;
  }

  setInitSettingsConfirm(array: ISettingsSelected[]) {
    this.initSettingsConfirm = array;
  }

  setSelectedList(array: ISettingsSelectedGroup[]) {
    this.selectLists = array;
  }

  setConfirmList(array: ISettingsSelected[]) {
    this.confirmLists = array;
  }

  setSelectedChecked(params: setCheckedParams) {
    const { code, group } = params;

    this.selectLists.map((groupArr) =>
      groupArr.group === group
        ? groupArr.fields.map((item) =>
            item.code === code ? (item.isSelected = !item.isSelected) : item
          )
        : groupArr
    );
  }

  addToConfirmList(item: ISettingsSelected) {
    const newArr: any[] = [
      ...this.confirmLists,
      { ...item, ordering: this.confirmLists.length + 1 },
    ];

    this.confirmLists = newArr;
  }

  showSuccsecDeletedChannel = (value: boolean | null) => {
    this.showSuccsecDeleted = value;
  };

  changeSimpleNumEdit = (id: number, field: string, newValue: number, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: newValue,
        };
        return newItem;
      } else return item;
    });
  };

  addLastComment = (id: number, user: IPostedCommentItem) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        return { ...item, posted_comments: [...item.posted_comments, user] };
      }
      return item;
    });
  };

  onUpdateCommentsArray = (id: number, comments: IPostedCommentItem[]) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        return { ...item, posted_comments: comments };
      }
      return item;
    });
  };

  updateAudience = (id: number, audienceDate: IChannelAudienceRequest, update_id: number) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        return { ...item, update_id, ...audienceDate };
      } else return item;
    });
  };

  changePredictFields = (
    id: number,
    field: string,
    newValue: number,
    channelData: IChannelFullResponse
  ) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          [field]: newValue,
          update_id: channelData?.update_id,
          crSite: channelData?.crSite,
          minRequests: channelData?.minRequests,
          minOrders: channelData?.minOrders,
          roiPredict: channelData?.roiPredict,
          viewsPredict: channelData?.viewsPredict,
          cpvPredict: channelData?.cpvPredict,
          ctrPredict: channelData?.ctrPredict,
          roiNewPredict: channelData.roiNewPredict,
          cpvNewPredict: channelData?.cpvNewPredict,
          trafficPredict: channelData?.trafficPredict,
        };
        return newItem;
      } else return item;
    });
  };

  removeConfirmList(id: string) {
    const newArr: any[] = [
      ...this.confirmLists
        .filter((item) => item.code !== id)
        .map((item, i) => {
          return { ...item, ordering: i + 1 };
        }),
    ];

    this.confirmLists = newArr;
  }

  changeTheme(id: number, value: IChannelTheame, update_id: number) {
    const item = this.tableItems.find((x) => x.id === id);
    const index = this.tableItems.findIndex((x) => x.id === id);

    const newItem = {
      ...item,
      update_id,
      theme: value,
    };

    this.tableItems[index] = newItem;
  }

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;

    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value,
        };
        return newItem;
      } else return item;
    });
  };

  deleteChannel = (id: number) => {
    this.tableItems = this.tableItems.filter((x) => x.id !== id);
  };

  onConfirmDelete(id: string) {
    this.selectLists.map((groupArr) =>
      groupArr.fields.map((item) =>
        item.code === id ? (item.isSelected = !item.isSelected) : item
      )
    );
  }

  onDragStart = (params: IDragParams) => {
    this.curDragItemParams = params;
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent, params: IDragParams) => {
    e.preventDefault();

    if (
      params.code &&
      params.ordering &&
      this.curDragItemParams?.code &&
      this.curDragItemParams?.ordering &&
      params.code !== this.curDragItemParams.code
    ) {
      const dragItem = { code: this.curDragItemParams.code, ordering: params.ordering };
      const downItem = {
        params,
        code: params.code,
        ordering:
          this.curDragItemParams.ordering < params.ordering
            ? params.ordering - 1
            : params.ordering + 1,
      };

      const afterDragArr: any[] = this.confirmLists.map((item) => {
        if (item.code === dragItem.code) {
          return { ...item, ...dragItem };
        } else if (item.code === downItem.code) {
          return { ...item, ...downItem };
        } else {
          return {
            ...item,
            ordering: item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1,
          };
        }
      });

      this.confirmLists = afterDragArr
        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
        .map((x, i) => {
          return { ...x, ordering: i + 1 };
        });
    }
  };

  sortTableItem(type: string, sortType: "asc" | "desc") {
    this.isLoadingSort = true;

    setTimeout(() => {
      this.tableItems = [...this.tableItems].sort((a, b) => {
        let itemA, itemB;

        if (type === "theme") {
          itemA = a[type]?.name ?? "";
          itemB = b[type]?.name ?? "";
        } else if (type === "roi") {
          itemA = a[type] != null ? Number(a[type]) : 0;
          itemB = b[type] != null ? Number(b[type]) : 0;
        } else {
          itemA = a[type] ?? "";
          itemB = b[type] ?? "";
        }

        itemA = typeof itemA === "string" ? itemA.toLowerCase() : itemA;
        itemB = typeof itemB === "string" ? itemB.toLowerCase() : itemB;

        if (itemA === itemB) return 0;
        if (sortType === "asc") {
          return itemA < itemB ? -1 : 1;
        } else {
          return itemA > itemB ? -1 : 1;
        }
      });

      setTimeout(() => {
        this.isLoadingSort = false;
      }, 0);
    }, 0);
  }
}

export default new ChannelTableStore();
