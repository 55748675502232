import { FC, useState } from 'react'
import { IOplataStatus } from './IOplataStatus'
import { IPaymentUpdateStatusRequest } from '../../../../../../services/payments/IPaymentsRequest'
import PaymentsService from '../../../../../../services/payments/payments.service'
import { AxiosError } from 'axios'
import { StatusesList } from '../../../../../../components/Statuses/StatusesList'

export const OplataStatus:FC<IOplataStatus> = (props) => {
  const {
    statusType, 
    statusStore, 
    onChangeStatus,
    id,
    readOnly,
    update_id,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isShowAlert, setShowAlert] = useState(false)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const changeStatus = (value:string) => {
     setLoading(true)
     setError(null)

     const option: IPaymentUpdateStatusRequest = {
       id:id,
       update_id,
       entity:'payment',
       newStatus:value
     }
     PaymentsService.updateStatus(option)
       .then(res=>{
         // console.log(res, 'res change sttuas')
         onChangeStatus(res.data)
         setLoading(false)
         handleClose()
       })
       .catch(err=>{
         if(err instanceof AxiosError){
           // console.log(err, 'err change stuts')
           setLoading(false)
           setError(err?.response?.data?.message)
           handleClose()
         }
       })
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StatusesList
      type={statusType}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={statusStore}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={()=>setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={()=>setError(null)}
      readOnly={readOnly}
      ingoreTooltip={readOnly}
    />
  )
}
