import { AxiosProgressEvent, AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import {
  IChannelBlockRequest,
  IChannelChangeThemeRequest,
  IChannelChangeVerifiedByGov,
  IChannelCreateRequest,
  IChannelEditAccountRequest,
  IChannelInsertAccountRequest,
  IChannelRequest,
  IChannelUpdateStatisticRequest,
  IRemoveChannelRequest,
} from "./IChannelRequest";
import {
  IChannel,
  IChannelBannedResponse,
  IChannelBlockResponse,
  IChannelFullResponse,
  IChannelInsertAccountResponse,
  IChannelMinMaxResponse,
  IChannelResponse,
  IChannelTransferredResponse,
  IChannelGovVerificationResponse,
} from "./ICnannelResponse";
import { IUpdateStatusesRequest } from "../integration/IntegrationsRequest";
import { IUpdateStatusesResponse } from "../integration/IIntegrationsResponse";

export default class ChannelService {
  static getChannelItems(option: IChannelRequest): Promise<AxiosResponse<IChannelResponse>> {
    return $gateway_api.post("/api/channels/filtered", option);
  }

  static getChannelFull(id: string | number): Promise<AxiosResponse<IChannelFullResponse>> {
    return $gateway_api.get(`api/channels/full/${id}`);
  }

  static changeTheme(
    option: IChannelChangeThemeRequest
  ): Promise<AxiosResponse<{ update_id: number }>> {
    return $gateway_api.post("/api/channels/theme", option);
  }

  static updatePrice(option: IUpdateStatusesRequest): Promise<AxiosResponse<IChannelFullResponse>> {
    return $gateway_api.post("/api/channels/update-price", option);
  }

  static updateStatus(
    option: IUpdateStatusesRequest
  ): Promise<AxiosResponse<IUpdateStatusesResponse>> {
    return $gateway_api.post("/api/channels/update-status", option);
  }

  static updateSimpleField(
    option: IUpdateStatusesRequest
  ): Promise<AxiosResponse<IChannelFullResponse>> {
    return $gateway_api.post("/api/channels/update-simple-field", option);
  }

  static insertAccounts(
    option: IChannelInsertAccountRequest
  ): Promise<AxiosResponse<IChannelInsertAccountResponse>> {
    return $gateway_api.post("api/channels/insert-account", option);
  }

  static deleteAccounts(option: { id: string }): Promise<AxiosResponse> {
    return $gateway_api.post("/api/channels/delete-account", option);
  }

  static editAccounts(
    option: IChannelEditAccountRequest
  ): Promise<AxiosResponse<IChannelInsertAccountResponse>> {
    return $gateway_api.post("/api/channels/edit-account", option);
  }

  static getChannelMinMaxFilter(): Promise<AxiosResponse<IChannelMinMaxResponse>> {
    return $gateway_api.get("/api/channels/minMaxFilters");
  }

  static createChannel(
    option: IChannelCreateRequest
  ): Promise<AxiosResponse<IChannelFullResponse>> {
    return $gateway_api.post("/api/channels/create", option);
  }

  static channelBlock(option: IChannelBlockRequest): Promise<AxiosResponse<IChannelBlockResponse>> {
    return $gateway_api.post("/api/channels/block", option);
  }

  static getChannelBanned(channelId: number): Promise<AxiosResponse<IChannelBannedResponse>> {
    return $gateway_api.get("/api/channels/banned/" + channelId);
  }

  static updateStatisticData(
    option: IChannelUpdateStatisticRequest
  ): Promise<AxiosResponse<IChannelFullResponse>> {
    return $gateway_api.post("/api/channels/update-statistic-data", option);
  }

  static channelsFilteredExcel(
    option: IChannelRequest,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<Blob>> {
    return $gateway_api.post("/api/channels/filtered/excel", option, {
      responseType: "blob",
      onDownloadProgress,
    });
  }
  static getTransferredChannels(
    userId?: number
  ): Promise<AxiosResponse<IChannelTransferredResponse>> {
    return $gateway_api.get(`/api/channels/get-channels-transferred?userId=${userId}`);
  }

  static getProcessedChannels(): Promise<AxiosResponse<IChannel[]>> {
    return $gateway_api.get("/api/channels/get-channels-processed");
  }

  static removeChannel(option: IRemoveChannelRequest): Promise<AxiosResponse<{ id: number }>> {
    return $gateway_api.post("/api/channels/remove-channel", option);
  }

  static changeVerificationByGov(
    option: IChannelChangeVerifiedByGov
  ): Promise<AxiosResponse<IChannelGovVerificationResponse>> {
    return $gateway_api.post("/api/channels/gov/verification", option);
  }
}
