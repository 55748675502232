import { FC, useMemo, useState } from "react";
import { IChannelAsideMain } from "./IChannelAsideMain";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import { ChannelAsideSimpleEdit } from "../ChannelAsideSimpleEdit/ChannelAsideSimpleEdit";
import { ChannelAsidePrice } from "../ChannelAsidePrice/ChannelAsidePrice";
import { AsideAddTheme } from "../AsideAddTheme/AsideAddTheme";
import { ChannelAsideStatus } from "../ChannelAsideStatus/ChannelAsideStatus";
import { AsideCardStatusEmpty } from "../AsideCardStatusEmpty/AsideCardStatusEmpty";
import { AddReleaseManager } from "../AddReleaseManager/AddReleaseManager";
import { ChannelDate } from "../ChannelDate/ChannelDate";
import { observer } from "mobx-react-lite";
import channelStore from "../../../../store/channel.store";
import { IChannelTheame } from "../../../../entities/ChannelTheame/models/IChannelTheame";
import { IChannelChangeThemeRequest } from "../../../../services/channel/IChannelRequest";
import ChannelService from "../../../../services/channel/channel.service";
import { AxiosError } from "axios";
import channelTableStore from "../../../../store/channelTable.store";
import { AsideCardThemeList } from "../../../TableAsidePage/ui/AsideCardThemeList/AsideCardThemeList";
import channelTheame from "../../../../store/channelTheame";
import statusesStore from "../../../../store/statuses.store";
import channelCardStore from "../../../../store/channelCard.store";
import { IChannelFullResponse } from "../../../../services/channel/ICnannelResponse";
import userStore from "../../../../store/user.store";
import { ALL_RULES } from "../../../../shared/rules/interface/EnumRights";
import { Blocked } from "../Blocked/Blocked";
import { ChannelDateGiven } from "../ChannelDateGiven/ChannelDateGiven";
import { ChannelDeleteBtn } from "./ui/ChannelDeleteBtn/ChannelDeleteBtn";
import { showDeleteBtn } from "./shared/functions";
import { PaymentOnCard } from "./ui/PaymentOnCard/PaymentOnCard";
import { IUpdateStatusesRequest } from "../../../../services/integration/IntegrationsRequest";
import channelProcessedStore from "../../../../store/channelProcessed.store";
import transferredChannelsStore from "../../../../store/transferredChannels.store";
import integrationTableStore from "../../../../store/integrationTable.store";

export const ChannelAsideMain: FC<IChannelAsideMain> = observer((props) => {
  const { urlId, data, update_id, setUpdateId, readOnly, channelName, setChannelName } = props;

  const { firstName, lastName } = data.foundBy || {};
  const [link, setLink] = useState<string | null>(data.link);
  const [contactValue, setContactValue] = useState<string | null>(data.contact);
  const [agencyValue, setAgencyValue] = useState<string | null>(data.agency);
  const [themeValue, setThemeValue] = useState<IChannelTheame | null>(data.theme);
  const [statusType, setStatusType] = useState<string | null>(
    data.status?.code ? data.status?.code : null
  );
  const [dateFound, setDateFound] = useState<string | null>(data.dateFound);
  const [dateGiven, setDateGiven] = useState<string | null>(data.dateGiven);
  const [commissionValue, setCommissionValue] = useState<string>(String(data.commission));

  const changeInitialPriceAndPridictFields = (
    currentId: number,
    field: string,
    newValue: number,
    channelData: IChannelFullResponse
  ) => {
    channelCardStore.setCrSite(channelData.crSite);
    channelCardStore.setMinRequests(Math.round(channelData.minRequests || 0));
    channelCardStore.setMinOrders(Math.round(channelData.minOrders || 0));
    channelCardStore.setRoiPredict(channelData.roiPredict);
    channelCardStore.setViewsPredict(channelData.viewsPredict);
    channelCardStore.setCpvPredict(channelData.cpvPredict);
    channelCardStore.setCtrPredict(channelData.ctrPredict);
    channelCardStore.setInitialPrice(channelData.initialPrice);

    channelTableStore.changePredictFields(currentId, field, +newValue, channelData);
    channelCardStore.setTrafficPredict(channelData.trafficPredict);
  };

  const changePriceAndPridictFields = (
    currentId: number,
    field: string,
    newValue: number,
    channelData: IChannelFullResponse
  ) => {
    channelCardStore.setPrice(channelData.price);

    channelCardStore.setCpvNewPredict(channelData.cpvNewPredict);
    channelCardStore.setRoiNewPredict(channelData.roiNewPredict);

    channelTableStore.changePredictFields(currentId, field, +newValue, channelData);
    channelCardStore.setTrafficPredict(channelData.trafficPredict);
  };

  const changeTheme = (value: string, code: string, isActive: boolean) => {
    if (update_id) {
      const theme: IChannelChangeThemeRequest = {
        id: +urlId!,
        new_theme: {
          code,
          isActive,
          name: value,
        },
        update_id: update_id,
      };
      ChannelService.changeTheme(theme)
        .then((res) => {
          // console.log(res, 'res change theme')
          setUpdateId(res.data.update_id);
          channelTableStore.changeTheme(
            +urlId!,
            { code, isActive, name: value },
            res.data.update_id
          );
          setThemeValue({ code, isActive, name: value });
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, 'change theme err')
          }
        });
    }
  };

  const checkUserEdit = (): boolean => {
    if (userStore?.user?.rights?.find((x) => x.code === ALL_RULES.ADMIN)) {
      return true;
    } else if (userStore.user?.rights.find((x) => x.code === ALL_RULES.block_channel)) {
      return true;
    }
    if (userStore.user?.id === data?.foundBy?.id) {
      return true;
    } else return false;
  };

  const checkUserDateFoundEdit = (): boolean => {
    if (
      userStore?.user?.rights?.find(
        (x) => x.code === ALL_RULES.ADMIN || x.code?.includes(ALL_RULES.search)
      )
    ) {
      return true;
    } else return false;
  };

  const getStatusAllowedMoves = useMemo((): string[] => {
    return Object.keys(statusesStore.channel);
  }, []);

  const changeFieldHandler = (option: IUpdateStatusesRequest) => {
    integrationTableStore.changeField(option);
    channelTableStore.changeField(option);
    channelProcessedStore.changeField(option);
    transferredChannelsStore.changeField(option);
    transferredChannelsStore.changeAccordionField(option);
  };

  return (
    <AsideCardLayout
      headerTitle="Основное"
      rightContent={
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"}>
            {channelCardStore.isPaymentOnCard !== null && (
              <PaymentOnCard
                service={ChannelService}
                onChangeUpdateId={setUpdateId}
                id={urlId}
                update_id={update_id}
                isPaymentOnCard={channelCardStore.isPaymentOnCard}
                readOnly={!checkUserEdit()}
                setPaymentOnCard={channelCardStore.setPaymentOnCard}
                changeField={changeFieldHandler}
              />
            )}
          </Stack>
          {channelCardStore.isBanned !== null ? (
            <Blocked
              channelId={urlId}
              isBanned={channelCardStore.isBanned}
              changeBanned={channelCardStore.setBanned}
              update_id={update_id}
              readOnly={!checkUserEdit()}
              onChangeUpdateId={setUpdateId}
            />
          ) : (
            <Skeleton sx={{ height: 25, width: 75 }} />
          )}
        </Stack>
      }
      sx={{ pt: 1.5 }}>
      <Stack spacing={"6px"} sx={{ mt: 1 }}>
        <ChannelAsideSimpleEdit
          link={link ? link : ""}
          id={+urlId}
          field="name"
          update_id={update_id}
          title="Имя канала:"
          value={channelName}
          setValue={setChannelName}
          placeholder="Введите имя канала"
          notFoundTitle="Введите имя канала"
          icon={
            <Avatar
              src={channelStore?.channels![data?.type?.code]?.icon}
              alt={data?.type?.code}
              sx={{ height: 20, width: 20 }}
            />
          }
          onChangeUpdateId={setUpdateId}
          tooltipPlacement="bottom"
          readOnly={readOnly}
          isCopy
        />
        <ChannelAsidePrice
          id={+urlId}
          title="Прайс:"
          field_code="initialPrice"
          price={channelCardStore.initialPrice}
          setPrice={channelCardStore.setInitialPrice}
          update_id={update_id}
          onChangeUpdateId={setUpdateId}
          readOnly={readOnly}
          onChangePredict={changeInitialPriceAndPridictFields}
        />
        <ChannelAsidePrice
          id={+urlId}
          title="Стоимость:"
          field_code="price"
          price={channelCardStore.price}
          setPrice={channelCardStore.setPrice}
          update_id={update_id}
          onChangeUpdateId={setUpdateId}
          readOnly={readOnly}
          onChangePredict={changePriceAndPridictFields}
        />
        <ChannelAsideSimpleEdit
          id={+urlId}
          field="link"
          title="Ссылка:"
          update_id={update_id}
          value={link ? link : ""}
          setValue={setLink}
          notFoundTitle="Введите ссылку"
          placeholder="Введите ссылку"
          onChangeUpdateId={setUpdateId}
          readOnly={readOnly}
          isCopy
        />
        <ChannelAsideSimpleEdit
          isPercent
          id={+urlId}
          field="commission"
          title="Комиссия:"
          update_id={update_id}
          value={commissionValue ? commissionValue.replace(".", ",") : ""}
          setValue={setCommissionValue}
          onChangeUpdateId={setUpdateId}
          tooltipPlacement="top"
          readOnly={readOnly}
          placeholder="Введите размер комиссии"
          notFoundTitle="Введите размер комиссии"
        />
        <ChannelAsideSimpleEdit
          id={+urlId}
          field="contact"
          title="Прямой контакт:"
          update_id={update_id}
          value={contactValue ? contactValue : ""}
          setValue={setContactValue}
          notFoundTitle="Введите контакт"
          placeholder="Введите адрес"
          onChangeUpdateId={setUpdateId}
          isCopy
          tooltipPlacement="top"
          readOnly={readOnly}
        />
        <ChannelAsideSimpleEdit
          id={+urlId}
          field="agency"
          setValue={setAgencyValue}
          title="Агенство:"
          update_id={update_id}
          value={agencyValue ? agencyValue : ""}
          notFoundTitle="Введите агенство"
          placeholder="Введите адрес"
          onChangeUpdateId={setUpdateId}
          isCopy
          tooltipPlacement="bottom"
          readOnly={readOnly}
        />
        <Typography>
          Размер: {channelCardStore.channelSize ? channelCardStore.channelSize : "не описан"}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "start", sm: "center" }}
          spacing={statusType ? 1 : 0}>
          <Typography>Статус:</Typography>
          {statusType && (
            <ChannelAsideStatus
              id={+urlId}
              update_id={update_id}
              type={statusType}
              setStatus={setStatusType}
              onChangeUpdateId={setUpdateId}
              readOnly={readOnly}
              allAllowedMoves={getStatusAllowedMoves}
              isVerifiedByGov={channelCardStore.govVerification?.isVerifiedByGov || false}
            />
          )}
          {statusType === null && (
            <AsideCardStatusEmpty
              id={+urlId}
              update_id={update_id}
              setStatus={setStatusType}
              onChangeUpdateId={setUpdateId}
              readOnly={readOnly}
            />
          )}
          {userStore.user && showDeleteBtn(userStore.user) && (
            <ChannelDeleteBtn id={+urlId} update_id={update_id} data={data} />
          )}
        </Stack>
        {themeValue && channelTheame?.theame && (
          <Box display={{ xs: "stretch", sm: "flex" }} alignItems={{ xs: "start", sm: "center" }}>
            <Typography minWidth={"180px"}>Тематика:</Typography>
            <Stack spacing={1} overflow={"hidden"} maxWidth={"350px"} width={"100%"}>
              <AsideCardThemeList
                label={false}
                value={themeValue}
                onChange={changeTheme}
                readOnly={readOnly}
                lists={channelTheame?.theame
                  ?.filter((x) => x?.isActive === true)
                  ?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1))}
                channelId={urlId}
                setThemeValue={setThemeValue}
                update_id={update_id}
                setUpdateId={setUpdateId}
              />
            </Stack>
          </Box>
        )}
        {themeValue === null && channelTheame?.theame && (
          <AsideAddTheme
            onChange={changeTheme}
            lists={channelTheame?.theame
              ?.filter((x) => x?.isActive === true)
              ?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1))}
            readOnly={readOnly}
            channelId={urlId}
            setThemeValue={setThemeValue}
            update_id={update_id}
            setUpdateId={setUpdateId}
          />
        )}
        <Box display={{ xs: "stretch", sm: "flex" }} alignItems={{ xs: "start", sm: "center" }}>
          <Typography minWidth={"180px"}>Найден менеджером:</Typography>
          {userStore.user?.rights.some(
            (el) => el.code === ALL_RULES.edit_foundById || el.code === ALL_RULES.ADMIN
          ) ? (
            <Stack spacing={0.5} width={"100%"} maxWidth={"350px"} overflow={"hidden"}>
              <AddReleaseManager
                id={+urlId}
                initValue={data?.foundBy?.id || undefined}
                update_id={update_id}
                onChangeUpdateId={setUpdateId}
                field={"foundBy"}
                fieldNotCode="foundById"
                readOnly={readOnly}
              />
            </Stack>
          ) : (
            data.foundBy && <Typography>{`${firstName} ${lastName}`}</Typography>
          )}
        </Box>
        <Box display={{ xs: "stretch", sm: "flex" }} alignItems={{ xs: "start", sm: "center" }}>
          <Typography minWidth={"180px"}>Выпускающий менеджер:</Typography>
          {userStore.user?.rights.some(
            (el) => el.code === ALL_RULES.edit_releaseById || el.code === ALL_RULES.ADMIN
          ) ? (
            <Stack spacing={0.5} width={"100%"} maxWidth={"350px"} overflow={"hidden"}>
              <AddReleaseManager
                id={+urlId}
                initValue={data?.releaseBy?.id || undefined}
                update_id={update_id}
                onChangeUpdateId={setUpdateId}
                field={"releaseBy"}
                fieldNotCode="releaseById"
                readOnly={readOnly}
              />
            </Stack>
          ) : data.releaseBy ? (
            <Typography>{`${data.releaseBy.firstName} ${data.releaseBy.lastName}`}</Typography>
          ) : (
            <Typography>не назначен</Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Typography noWrap sx={{ mr: 0.5 }}>
            Был найден:
          </Typography>
          <ChannelDate
            initValue={dateFound}
            id={+urlId}
            update_id={update_id}
            onChangeUpdateId={setUpdateId}
            setInitValue={setDateFound}
            readOnly={readOnly}
            hiddenEdit={!checkUserDateFoundEdit()}
            field="dateFound"
            maxWidth={"min-content"}
          />
        </Box>
        <ChannelDateGiven
          initValue={dateGiven}
          id={+urlId}
          update_id={update_id}
          onChangeUpdateId={setUpdateId}
          setInitValue={setDateGiven}
          field="dateGiven"
          readOnly={readOnly}
        />
      </Stack>
    </AsideCardLayout>
  );
});
