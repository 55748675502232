import { useCallback, useMemo, useState } from "react";
import statusesStore from "../../../../../store/statuses.store";
import { TableItem } from "../../../../../components/Table/ui/TableItem";
import ChannelService from "../../../../../services/channel/channel.service";
import { IChannelTransferredList } from "./IChannelTransferredList";
import { useChannelNavigation } from "../../../../../hooks/useChannelNavigation";
import { IBurgerItem } from "../../../../../components/Table/models/ITableItem";
import { OpenInNew, PostAdd } from "@mui/icons-material";
import { CHANNELS_ROUTES } from "../../../../../shared/routes/routes";
import { useNavigate } from "react-router-dom";

export const ChannelTransferredList = (props: IChannelTransferredList) => {
  const {
    channels,
    readOnly,
    onChange,
    onChangeManager,
    readOnlyDateGiven,
    onChangeStatus,
    cells,
  } = props;

  const navigateChannel = useChannelNavigation();

  const navigate = useNavigate();

  const navigateAside = useCallback((id: string | number) => {
    navigate(`${id}/${CHANNELS_ROUTES.INTEGRATION}`);
  }, []);

  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title: "Открыть и посмотреть",
      onNavigate: navigateChannel,
      isShow: true,
      icon: <OpenInNew />,
    },
    {
      title: "Создать интеграцию",
      onNavigate: navigateAside,
      isShow: true,
      icon: <PostAdd />,
    },
  ]);

  const getStatusAllowedMoves = useMemo((): string[] => {
    return Object.keys(statusesStore.channel);
  }, []);

  return (
    <>
      {channels.map((item) => {
        const channelProps = {
          ...item,
          theme: item?.theme?.name,
          readOnly,
          onChange,
          onChangeManager,
          fieldNotCode: "releaseById",
        };
        return (
          <TableItem
            {...channelProps}
            controlButtonPanel={controlButtonPanel}
            readOnlyDateGiven={readOnlyDateGiven}
            onChangeStatus={onChangeStatus}
            onUpdateStatus={ChannelService.updateSimpleField}
            statusStore={statusesStore.channel}
            cells={cells}
            key={item.id}
            allAllowedMoves={getStatusAllowedMoves}
          />
        );
      })}
    </>
  );
};
