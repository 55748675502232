import {FC, useState} from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { IntegrationLink } from '../IntegrationLink/IntegrationLink'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import { IAddShortLink } from './IAddShortLink'
import { AxiosError } from 'axios'
import { NotificationAlerts } from '../../../../../../components/NotificationAlerts/NotificationAlerts'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import { AddedButton } from '../../../../../../components/UI/Buttons/AddedButton/AddedButton'
import { Add } from '@mui/icons-material'

export const AddShortLink:FC<IAddShortLink> = (props) => {
  const {
    id, 
    readOnly, 
    setShortLink, 
    shortLink,
    setUpdateId,
    update_id
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isShowSucsessAlert, setShowSucsessAlert] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const createShortLink = () => {
    setLoading(true)
    setError(null)

    IntegrationsService.integrationCreateShortLink({id})
      .then((res:any)=>{
        // console.log(res, 'res add short link')
        integrationTableStore.updateSimpleField(id, res.data.update_id, 'shortLink', res.data.shortLink)
        setUpdateId(res.data.update_id)
        setShortLink(res.data.shortLink)
        setShowSucsessAlert(true)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err add short link')
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        {shortLink === null
          ? <>
              <Typography>Сокращенная ссылка:</Typography>
              <AddedButton
                size='small'
                variant='outlined'
                disabled={isLoading || readOnly}
                onClick={createShortLink}
                endIcon={isLoading ? <CircularProgress sx={{minHeight:20, maxHeight:20, minWidth:20, maxWidth:20}}/> : <Add fontSize='small' />}
              >
                добавить
              </AddedButton>
            </>
          : <IntegrationLink 
              title='Сокращенная ссылка:' 
              link={shortLink}
              id={id}
              field='shortLink'
              update_id={update_id}
              setUpdateId={setUpdateId}
              setLink={setShortLink}
              deleteModalTitle={'Вы действительно хотите удалить короткую ссылку?'}
              readOnly={readOnly}
            />
        }
      </Stack>
      <NotificationAlerts
        sucsess={{text:'Ссылка создана!', open:isShowSucsessAlert, onClose:()=>setShowSucsessAlert(false)}}
        error={{text:isError ? isError : '', open:Boolean(isError), onClose:()=>setError(null)}} 
      />
    </>
  )
}