import { FC, useState, useEffect } from "react";
import { Box, Menu, Stack, Tooltip, Typography } from "@mui/material";
import { IStatusesList } from "./models/IStatusesList";
import { StatusItem } from "./ui/StatusItem";
import { IStatusItem } from "./models/IStatusItem";
import { NotificationAlerts } from "../NotificationAlerts/NotificationAlerts";
import { checkVerifiedAndStatus } from "../../pages/ChannelCardPage/ui/ChannelAsideStatus/shared/checkVerifiedAndStatus";

export const StatusesList: FC<IStatusesList> = (props) => {
  const {
    paymentType,
    type,
    statusStore,
    readOnly = false,
    onOpenList,
    onCloseList,
    anchorEl,
    showAlertSuccess,
    closeAlertSuccess,
    isShowAlerError,
    closeAlertError,
    loading,
    changeStatus,
    integrationId,
    allAllowedMoves,
    onChangeType,
    payAlertModal,
    payContentData,
    wrap,
    isStopAlert,
    stopAlertText,
    stopCodeInList,
    isVerifiedByGov = true,
    ingoreTooltip,
  } = props;

  const [typeStatus, setTypeStatus] = useState<string>(type);

  const open = Boolean(anchorEl);

  const currentStatus = statusStore[typeStatus];

  const getContrastTextColor = (backgroundColor: string) => {
    if (backgroundColor?.length === 0) {
      return;
    }
    const red = parseInt(backgroundColor?.substring(1, 3), 16);
    const green = parseInt(backgroundColor?.substring(3, 5), 16);
    const blue = parseInt(backgroundColor?.substring(5, 7), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness > 150 ? "#000000" : "#FFFFFF";
  };
  const { name } = currentStatus;
  const content = paymentType === "netting" && name === "Оплачено" ? `${name} товаром` : name;

  const exception = checkVerifiedAndStatus(isVerifiedByGov, name, "Найденный");

  const verify = !readOnly && exception;

  const statusItemReadonly = (item: string) => {
    if (
      (item === "peredan" || item === "released" || item === "to_schedule" || item === "to_work") &&
      !isVerifiedByGov
    ) {
      return true;
    }

    return false;
  };

  const acces =
    (allAllowedMoves ? allAllowedMoves.length > 0 : currentStatus?.allowed_moves.length > 0) &&
    verify;

  useEffect(() => {
    setTypeStatus(type);
  }, [type]);

  return (
    <>
      <Box sx={{ display: "inline-flex" }}>
        <Tooltip
          title={
            ingoreTooltip || acces ? (
              ""
            ) : (
              <Typography>
                Функция недоступна. Необходимо заполнить информацию о регистрации на Госуслугах.
              </Typography>
            )
          }
          disableHoverListener={currentStatus?.allowed_moves.length === 0}>
          <Box
            sx={{
              background: currentStatus?.hexColor,
              p: 1,
              borderRadius: "4px",
              textAlign: "center",
              cursor: acces ? "pointer" : "default",
              "&:hover": {
                opacity: acces ? ".8" : "1",
              },
            }}
            onClick={acces ? (e) => onOpenList(e) : undefined}>
            <Typography
              variant="body1"
              color={getContrastTextColor(currentStatus?.hexColor)}
              sx={{
                whiteSpace: content.length > 15 ? "wrap" : "nowrap",
                lineHeight: "1",
                maxWidth: "min-content",
              }}>
              {content}
            </Typography>
          </Box>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={onCloseList}>
          <Stack direction={"column"} spacing={1} sx={{ pl: 1, pr: 1 }}>
            {allAllowedMoves
              ? allAllowedMoves.map((item: any) => (
                  <Box key={item}>
                    <StatusItem
                      isNettingPaymentType={paymentType === "netting"}
                      type={item}
                      statusStore={statusStore}
                      loading={loading}
                      onClick={item === ("agreement" as any) ? () => "" : () => changeStatus(item)}
                      integrationId={integrationId}
                      onChangeType={onChangeType}
                      payAlertModal={payAlertModal}
                      payContentData={payContentData}
                      wrap={wrap}
                      isStopAlert={item === stopCodeInList}
                      stopAlertText={stopAlertText}
                      isVerifiedByGov={isVerifiedByGov}
                      readOnly={statusItemReadonly(item)}
                    />
                  </Box>
                ))
              : currentStatus?.allowed_moves.map((item: IStatusItem["type"]) => (
                  <Box key={item}>
                    <StatusItem
                      isNettingPaymentType={paymentType === "netting"}
                      type={item}
                      statusStore={statusStore}
                      loading={loading}
                      onClick={item === ("agreement" as any) ? () => "" : () => changeStatus(item)}
                      integrationId={integrationId}
                      onChangeType={onChangeType}
                      payAlertModal={payAlertModal}
                      payContentData={payContentData}
                      wrap={wrap}
                      isStopAlert={item === stopCodeInList}
                      stopAlertText={stopAlertText}
                    />
                  </Box>
                ))}
          </Stack>
        </Menu>
      </Box>
      <NotificationAlerts
        sucsess={{
          text: "Статус успешно изменился!",
          open: showAlertSuccess ? showAlertSuccess : false,
          onClose: closeAlertSuccess ? closeAlertSuccess : () => "",
        }}
        error={{
          text: isShowAlerError ? isShowAlerError : "",
          open: isShowAlerError ? true : false,
          onClose: closeAlertError ? closeAlertError : () => "",
        }}
      />
    </>
  );
};
