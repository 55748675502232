import { FC, useState } from "react";
import { IReleaseList } from "./models/IReleaseList";
import { Avatar, Box, Link, Menu, Stack, Typography } from "@mui/material";
import channelStore from "../../store/channel.store";
import { ErrorOutline } from "@mui/icons-material";
import { NotificationAlerts } from "../NotificationAlerts/NotificationAlerts";
import { ReleaseItem } from "./ui/ReleaseItem";
import ReleasesService from "../../services/releases/releases.service";
import { AxiosError } from "axios";
import integrationTableStore from "../../store/integrationTable.store";
import { IReleasesRequestEdit } from "../../services/releases/IReleasesRequest";
import { CustomIconButton } from "../UI/Buttons/CustomIconButton/CustomIconButton";

export const ReleaseList: FC<IReleaseList> = (props) => {
  const { array, fullWidth = false, minWidth, id, readOnly } = props;

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<string | null>(null);

  const [copyAlert, setCopyAlert] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const currentIcon = channelStore?.channels?.[array[0]?.type?.code];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setAnchorEl(null);
    setCopyAlert(true);
  };

  const removeItem = (itemId: number) => {
    setLoading(true);

    ReleasesService.removeRelease(itemId)
      .then((res) => {
        // console.log(res, 'res remove releases in list')
        integrationTableStore.removeRelease(res.data);
        integrationTableStore.updateSimpleField(+id, res.data?.update_id, "views", res.data?.views);
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err remove relese item in list')
          setLoading(false);
        }
      });
  };

  const editItem = (item: IReleasesRequestEdit) => {
    setLoading(true);
    setError(null);

    const option: IReleasesRequestEdit = {
      id: item.id!,
      comments: item.comments,
      likes: item.likes,
      link: item.link,
      views: item.views,
      default: item.default,
      type: item.type,
      releaseDate: item.releaseDate,
      integrationPlace: item.integrationPlace,
    };

    ReleasesService.releaseEdit(option)
      .then((res) => {
        // console.log(res, 'res edit in modal')

        if (id && res.data.integrationId) {
          integrationTableStore.editRelease(res.data);
          integrationTableStore.updateSimpleField(
            +id,
            res.data?.update_id,
            "views",
            res.data?.views
          );
        }
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'res err edit vodal')
          setLoading(false);
          setError(
            Array.isArray(err.response?.data?.message)
              ? err.response?.data?.message?.join(", ")
              : err.response?.data?.message
          );
        }
      });
  };

  const editPlacement = (item: IReleasesRequestEdit, placement: string) => {
    setLoading(true);

    const option: IReleasesRequestEdit = {
      id: item.id!,
      comments: item.comments,
      likes: item.likes,
      link: item.link,
      views: item.views,
      default: item.default,
      type: item.type,
      releaseDate: item.releaseDate,
      integrationPlace: placement,
    };

    ReleasesService.releaseEdit(option)
      .then((res) => {
        // console.log(res, 'res edit in modal')

        if (id && res.data.integrationId) {
          integrationTableStore.editRelease(res.data);
        }
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'res err edit vodal')
          setLoading(false);
        }
      });
  };

  return (
    <>
      <Box sx={{ display: "grid", maxWidth: fullWidth ? "100%" : "220px" }}>
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "min-content 1fr min-content",
          }}>
          <Avatar
            src={currentIcon?.icon}
            alt={currentIcon?.name}
            sx={{ height: 20, width: 20, mr: 1 }}
          />
          <Link
            color={"secondary"}
            href={array[0].link}
            target="_blank"
            sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
            {array[0].link}
          </Link>
          <Stack direction={"row"} alignItems={"center"}>
            <CustomIconButton size="small" onClick={(e) => handleClick(e)}>
              <ErrorOutline fontSize="small" />
            </CustomIconButton>
            <Typography sx={{ mb: "1px" }}>({array.length})</Typography>
          </Stack>
        </Box>
        {
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {array.map((link) => (
              <ReleaseItem
                id={link.id}
                key={link.id + link.link}
                link={link.link}
                default={link.default}
                onClick={handleClose}
                onCopyText={() => copyText(link.link)}
                type={link.type}
                releaseDate={link.releaseDate}
                integrationPlace={link.integrationPlace}
                comments={link.comments}
                likes={link.likes}
                views={link.views}
                isDefault={link.default}
                fullWidth={fullWidth}
                minWidth={minWidth}
                onDelete={removeItem}
                isEditMode
                parentId={id}
                onEdit={editItem}
                loading={isLoading}
                readOnly={readOnly}
                lastUpdate={link.lastUpdate}
                wrap
                onChangePlacement={editPlacement}
              />
            ))}
          </Menu>
        }
      </Box>
      <NotificationAlerts
        sucsess={{
          text: "Успешно скопировалось!",
          autoHideDuration: 1000,
          open: copyAlert,
          onClose: () => setCopyAlert(false),
        }}
        error={{
          onClose: () => setError(null),
          open: Boolean(isError),
          text: isError ? isError : "",
        }}
      />
    </>
  );
};
