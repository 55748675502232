import { AxiosResponse } from "axios";
import $gateway_api from "../../shared/api/gatewayAxios";
import { IReleasesRequestEdit, IReleasesValidateViews } from "./IReleasesRequest";
import { IReleaseResponse } from "../integration/IIntegrationsResponse";

export default class ReleasesService {
  static removeRelease(id: number): Promise<AxiosResponse<IReleaseResponse>> {
    return $gateway_api.post("/api/releases/remove", { id });
  }

  static releaseEdit(option: IReleasesRequestEdit): Promise<AxiosResponse<IReleaseResponse>> {
    return $gateway_api.post("/api/releases/edit", option);
  }

  static releaseValidateViews(option: IReleasesValidateViews): Promise<AxiosResponse<Boolean>> {
    return $gateway_api.post("api/releases/validate-views-edit", option);
  }
}
