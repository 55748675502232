import { Stack, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { ICreateDocumentField } from "./ICreateDocumentField";

const CreateDocumentField: FC<ICreateDocumentField> = (props) => {
  const {
    title,
    name,
    onChange,
    placeholder,
    required,
    size = "small",
    alignItems = "center",
    maxWidth = "250px",
    minWidth,
    titleWidth = '200px',
    textCase = "inherit",
    ...rest
  } = props;
  const [error, setError] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (required) {
      validateRequired(value);
    }

    onChange(event);
  };

  const validateRequired = (value: string) => {
    if (required && !value) {
      setError("Заполните поле");
    } else {
      setError(null);
    }
  };

  return (
    <Stack direction={"row"} alignItems={alignItems}>
      {title && (
        <Typography minWidth={titleWidth} maxWidth={titleWidth}>
          {title}
          {": "}
          {required ? (
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          ) : (
            ""
          )}
        </Typography>
      )}
      <TextField
        required={required}
        name={name}
        onChange={handleChange}
        size={size}
        placeholder={placeholder}
        inputProps={{ sx: { textTransform: textCase } }}
        fullWidth
        sx={{
          maxWidth: maxWidth,
          minWidth: minWidth,
          position: "relative",
          "& .MuiFormHelperText-root": {
            margin: 0,
            padding: 0,
          },
        }}
        {...rest}
        error={!!error}
        helperText={
          error ? (
            <Typography
              variant="caption"
              color="error"
              component={"span"}
              sx={{
                position: "absolute",
                bottom: "-14px",
                left: "14px",
                margin: "0",
              }}>
              {error}
            </Typography>
          ) : (
            ""
          )
        }
      />
    </Stack>
  );
};

export default CreateDocumentField;
