import { ALL_ROLES } from "../../../../../shared/rules/interface/EnumRights";
import userStore from "../../../../../store/user.store";

export const checkVerifiedAndStatus = (
  isVerifiedByGov: boolean,
  status: string,
  exceptionStatus: string
) => {
  const exception = status === exceptionStatus;
  const checkUserSearchManager = userStore.user?.role === (ALL_ROLES.SEARCH_MANAGER || ALL_ROLES.SEARCH_HEAD_MANAGER);

  if (checkUserSearchManager) {
    return checkUserSearchManager
  }

  if (!isVerifiedByGov && exception) {
    return false;
  }

  if (!isVerifiedByGov && !exception) {
    return true;
  }

  return true;
};
