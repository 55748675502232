import { FC } from 'react'
import { IGuaranteeField } from './IGuaranteeField'
import { WbTwilight } from '@mui/icons-material'
import {Box, Stack} from "@mui/material";

export const GuaranteeField:FC<IGuaranteeField> = (props) => {
  const {color, isGuaranteed} = props

  return (
    <Stack direction={'row'} alignItems={'center'} gap={0.5}>
      <WbTwilight sx={{color}}/>
      {isGuaranteed && <Box color={color || '#34DD6E'} fontSize={'17px'} fontWeight={'bold'}>П</Box>}
    </Stack>
  )
}
