import { FC, useState, useEffect } from "react";
import { AsideCardRelease } from "../AsideCardRelease";
import { ICreateReleaseRequest } from "../../../../services/integration/IntegrationsRequest";
import { IReleaseItem } from "../../../../components/ReleaseList/models/IReleaseItem";
import IntegrationsService from "../../../../services/integration/integrations.service";
import integrationTableStore from "../../../../store/integrationTable.store";
import { AxiosError } from "axios";
import { ITableAsideRelease } from "./ITableAsideRelease";
import ReleasesService from "../../../../services/releases/releases.service";
import { IReleasesRequestEdit } from "../../../../services/releases/IReleasesRequest";
import integrationPageStore from "../../../../store/integrationPage.store";

export const TableAsideRelease: FC<ITableAsideRelease> = (props) => {
  const {
    id,
    readOnly,
    releases,
    onChangeUpdateId,
    setUpdateId,
    repeatIntegrationPromocode,
    repeatIntegrationId,
    handleOpenIntegration,
    isClone,
    setStatusValue,
  } = props;

  const [releasesArr, setReleasesArr] = useState<IReleaseItem[]>(releases);

  const [isOpenForm, setOpenForm] = useState(false);
  const [isOpenEditModal, setOpenModalEdit] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<string | null>(null);

  const [onCloseModalEdit, setCloseModalEdit] = useState(false);
  const [onClosePlacement, setClosePlacement] = useState(false);

  const addRelease = (item: IReleaseItem) => {
    setLoading(true);
    setError(null);

    const option: ICreateReleaseRequest = {
      id: +id,
      release: item,
    };

    IntegrationsService.createIntegrationRelease(option)
      .then((res) => {
        // console.log(res, 'res create pealrse'
        const release = res.data.release;
        const newArr = [...releasesArr, release];
        newArr.sort(
          (a, b) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime()
        );
        setReleasesArr(newArr);
        integrationTableStore.addRelease(res.data);

        integrationPageStore.setViews(res.data?.views);
        integrationPageStore.setGuarantee(res.data.guarantee);
        integrationPageStore.setRepeatIntegrationStatus(res.data.repeatIntegrationStatus);
        setStatusValue(res.data.status.code);
        integrationTableStore.updateSimpleField(+id, res.data?.update_id, "views", res.data?.views);

        setUpdateId(res.data.update_id);
        setOpenForm(false);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err create relese')
          setError(
            Array.isArray(err.response?.data?.message)
              ? err.response?.data?.message?.join(", ")
              : err.response?.data?.message
          );
          setLoading(false);
        }
      });
  };

  const editRelease = (item: IReleasesRequestEdit) => {
    if (item.link.trim() !== "" && item.type.trim() !== "" && item.releaseDate.trim() !== "") {
      setLoading(true);
      setError(null);

      ReleasesService.releaseEdit(item)
        .then((res) => {
          if (id && res.data.integrationId) {
            const release = res.data.release;
            integrationTableStore.editRelease(res.data);
            setReleasesArr((prev) =>
              prev
                .map((item) => (item.id === release.id ? release : item))
                .sort(
                  (a, b) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime()
                )
            );

            integrationPageStore.setViews(res.data?.views);
            integrationPageStore.setGuarantee(res.data?.guarantee);
            integrationPageStore.setRepeatIntegrationStatus(res.data.repeatIntegrationStatus);
            setStatusValue(res.data.status.code);

            integrationTableStore.updateSimpleField(
              +id,
              res.data?.update_id,
              "views",
              res.data?.views
            );
            integrationTableStore.changeGurange(
              +id,
              res.data?.guarantee || null,
              res.data?.update_id
            );
          }

          if (onChangeUpdateId) {
            onChangeUpdateId(res.data?.update_id);
          }

          setOpenModalEdit(false);
          setCloseModalEdit(true);
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, 'res err edit vodal')
            setLoading(false);
            setError(
              Array.isArray(err.response?.data?.message)
                ? err.response?.data?.message?.join(", ")
                : err.response?.data?.message
            );
          }
        });
    }
  };

  const deleteRelease = (itemId: number) => {
    //console.log(itemId, 'item id')
    setLoading(true);

    ReleasesService.removeRelease(itemId)
      .then((res) => {
        // console.log(res, 'res remove releases in list')
        integrationTableStore.removeRelease(res.data);
        setReleasesArr((prev) => prev.filter((item) => item.id !== itemId));

        integrationPageStore.setViews(res.data?.views);
        integrationPageStore.setGuarantee(res.data?.guarantee);
        integrationPageStore.setRepeatIntegrationStatus(res.data.repeatIntegrationStatus);
        setStatusValue(res.data.status.code);

        integrationTableStore.updateSimpleField(+id, res.data?.update_id, "views", res.data?.views);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err remove relese item in list')
          setLoading(false);
        }
      });
  };

  const editPlacement = (item: IReleasesRequestEdit, placement: string) => {
    const option: IReleasesRequestEdit = {
      id: item.id!,
      comments: item.comments,
      likes: item.likes,
      link: item.link,
      views: item.views,
      default: item.default,
      type: item.type,
      releaseDate: item.releaseDate,
      integrationPlace: placement.trim() !== "" ? placement : (null as any),
    };

    ReleasesService.releaseEdit(option)
      .then((res) => {
        // console.log(res, 'res edit in modal')

        if (id && res.data.integrationId) {
          const release = res.data.release;

          integrationPageStore.setViews(res.data?.views);
          integrationPageStore.setGuarantee(res.data?.guarantee);
          integrationPageStore.setRepeatIntegrationStatus(res.data.repeatIntegrationStatus);
          setStatusValue(res.data.status.code);

          integrationTableStore.editRelease(res.data);

          setReleasesArr((prev) => prev.map((item) => (item.id === release.id ? release : item)));

          if (onChangeUpdateId) {
            onChangeUpdateId(res.data?.update_id);
          }

          setClosePlacement(true);
        }
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'res err edit vodal')
        }
      });
  };

  useEffect(() => {
    if (onCloseModalEdit) {
      setCloseModalEdit(false);
    }
  }, [onCloseModalEdit]);

  useEffect(() => {
    if (onClosePlacement) {
      setClosePlacement(false);
    }
  }, [onClosePlacement]);

  return (
    <AsideCardRelease
      id={id}
      releases={releasesArr}
      onAddRelease={addRelease}
      onDelete={deleteRelease}
      onEdit={editRelease}
      loading={isLoading}
      readOnly={readOnly}
      isShowAddRelease={isOpenForm}
      setShowAddRelease={setOpenForm}
      onEditPlacement={editPlacement}
      onCloseModalEdit={onCloseModalEdit}
      onClosePlacement={onClosePlacement}
      alertErrorText={isError}
      closeErrorAlert={() => setError(null)}
      errorShowAlert={Boolean(isError)}
      repeatIntegrationPromocode={repeatIntegrationPromocode}
      repeatIntegrationId={repeatIntegrationId}
      handleOpenIntegration={handleOpenIntegration}
      isClone={isClone}
    />
  );
};
