import { makeAutoObservable } from "mobx";
import ReferencesService from "../services/references/references.service";
import { IFilterSelectItem } from "../components/Filters/UI/FilterSelectChip/IFilterSelectChip";

class OrdTextStore {
  ordTexts: IFilterSelectItem[] | null = null;

  ordEridTexts: IFilterSelectItem[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addOrdText = (ordText: IFilterSelectItem) => {
    if (this.ordTexts !== null) {
      this.ordTexts = [...this.ordTexts, ordText];
    }
  };

  addOrdEridText = (ordText: IFilterSelectItem) => {
    if (this.ordEridTexts !== null) {
      this.ordEridTexts = [...this.ordEridTexts, ordText];
    }
  };

  getOrdTexts() {
    ReferencesService.getOrdTexts().then((res) => {
      this.ordTexts = res.data.map((x) => {
        const newItem: IFilterSelectItem = {
          name: x?.text,
          value: `${x?.id}`,
          filterCode: `${x?.goodsCategory?.code}`,
        };
        return newItem;
      });
    });
  }

  getOrdEridTexts() {
    ReferencesService.getOrdEridTexts().then((res) => {
      const findRequiredItemIndex = res.data.findIndex(item => item.id === 7)
      if (findRequiredItemIndex !== -1) {
        const [requiredItem] = res.data.splice(findRequiredItemIndex, 1);
        res.data.unshift(requiredItem);
      }
      
      this.ordEridTexts = res.data.map((x) => {
        const newItem: IFilterSelectItem = {
          name: x?.text,
          value: `${x?.id}`,
        };
        return newItem;
      });
    });
  }
}

export default new OrdTextStore();
