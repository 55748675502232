import { FC, useState } from "react";
import { StatusesList } from "../../../../../../components/Statuses/StatusesList";
import { IIntegrationStatus } from "./IIntegrationStatus";
import IntegrationsService from "../../../../../../services/integration/integrations.service";
import { IUpdateStatusesRequest } from "../../../../../../services/integration/IntegrationsRequest";
import integrationTableStore from "../../../../../../store/integrationTable.store";
import { AxiosError } from "axios";
import statusesStore from "../../../../../../store/statuses.store";

export const IntegrationStatus: FC<IIntegrationStatus> = (props) => {
  const {
    id,
    readOnly,
    type,
    update_id,
    onChangeUpdateId,
    integrationId,
    setStatusValue,
    isVerifiedByGov,
    repeatIntegrationStatus
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isShowAlert, setShowAlert] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<null | string>(null);

  const changeStatus = (value: string) => {
    setLoading(true);
    setError(null);

    const option: IUpdateStatusesRequest = {
      id,
      field: "status",
      new_value: value,
      update_id,
    };
    IntegrationsService.updateIntegrationStatuses(option)
      .then((res) => {
        // console.log(res, 'res change sttuas')
        integrationTableStore.changeUpdateIdItem(id, res.data.update_id, value as any);

        setStatusValue(value);
        onChangeUpdateId(res.data.update_id);
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err change stuts')
          setLoading(false);
          setError(err?.response?.data?.message);
        }
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAgreementStatus = (type: string, update_id: number) => {
    integrationTableStore.changeUpdateIdItem(id, update_id, type as any);
    setStatusValue(type);
    onChangeUpdateId(update_id);
    handleClose();
  };
  
  return (
    <StatusesList
      type={type}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={statusesStore.statuses}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={() => setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={() => setError(null)}
      readOnly={readOnly || !isVerifiedByGov}
      integrationId={integrationId}
      isVerifiedByGov={isVerifiedByGov}
      onChangeType={changeAgreementStatus}
      ingoreTooltip={repeatIntegrationStatus}
    />
  );
};
