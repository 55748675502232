import { FC, useState } from "react";
import { IReleaseModalEdit } from "../models/IReleaseModalEdit";
import { Box, Dialog, DialogTitle, Divider, IconButton, SelectChangeEvent } from "@mui/material";
import { AsideReleaseForm } from "../../../pages/TableAsidePage/ui/AsideReleaseForm";
import { Cancel, Dataset } from "@mui/icons-material";
import dayjs from "dayjs";
import { IReleasesRequestEdit } from "../../../services/releases/IReleasesRequest";
import { NotificationAlerts } from "../../NotificationAlerts/NotificationAlerts";
import { sendDatePlusCustomTime } from "../../../shared/functions/functions";

export const ReleaseModalEdit: FC<IReleaseModalEdit> = (props) => {
  const {
    onClose,
    open,
    data,
    itemId,
    error,
    loading,
    onSubmit,
    alertErrorText,
    closeErrorAlert,
    errorShowAlert,
    releaseId,
  } = props;

  const [linkValue, setLinkValue] = useState(data.link);
  const [viewValue, setViewValue] = useState<string>(data.views?.toString());
  const [commentsValue, setCommentsValue] = useState<string>(data.comments?.toString());
  const [likeValue, setLikeValue] = useState<string>(data.likes?.toString());
  const [dateValue, setDateValue] = useState<Date | null>(
    data.releaseDate ? (dayjs(data.releaseDate) as any) : null
  );
  const [selectValueRelease, setSelectValueRelease] = useState(data.type?.code);
  const [selectPlacementValue, setSelectPlacementValue] = useState(
    data.integrationPlace?.code ? data.integrationPlace?.code : ""
  );
  const [isDefault, setIsDefault] = useState(data.default);

  const [isDateEmpty, setDateEmpty] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValueRelease(event.target.value as string);
  };

  const handleChangePlacement = (event: SelectChangeEvent) => {
    setSelectPlacementValue(event.target.value as string);
  };

  const submit = () => {
    if (dateValue) {
      const item: IReleasesRequestEdit = {
        id: data.id!,
        comments: +commentsValue,
        likes: +likeValue,
        link: linkValue,
        releaseDate: sendDatePlusCustomTime({ value: dateValue?.toISOString() }),
        type: selectValueRelease,
        default: data.default,
        views: +viewValue,
        integrationPlace:
          selectPlacementValue?.trim() !== "" ? selectPlacementValue : (null as any),
      };

      if (onSubmit) {
        onSubmit(item);
      }
    } else setDateEmpty(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: { xs: 1, sm: 2 },
          pr: 1,
          fontSize: { xs: "14px", sm: "1.07rem" },
        }}>
        Форма редактирования релиза
        <IconButton onClick={onClose}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box sx={{ p: { xs: 1, sm: 2 } }}>
        <AsideReleaseForm
          releaseId={releaseId}
          typeValue={data?.type?.code}
          linkValue={linkValue}
          onChangeLink={(e) => setLinkValue(e.target.value)}
          commentsValue={commentsValue}
          onChangeComments={(e) => setCommentsValue(e.target.value)}
          dateValue={dateValue}
          onChangeDate={setDateValue}
          likeValue={likeValue}
          onChangeLike={(e) => setLikeValue(e.target.value)}
          selectValueRelease={selectValueRelease}
          onChangeSelect={handleChange}
          viewValue={viewValue}
          onChangeView={(e) => setViewValue(e.target.value)}
          isDefault={isDefault}
          onChangeIsDefault={(e) => setIsDefault(e.target.value === "true")}
          timeValue={dateValue}
          onChangeTime={setDateValue}
          onClose={onClose}
          onSubmit={submit}
          error={isDateEmpty || error ? true : false}
          loading={loading}
          placementValue={selectPlacementValue}
          onChangePlacement={handleChangePlacement}
        />
      </Box>
      <NotificationAlerts
        error={{
          onClose: closeErrorAlert ? closeErrorAlert : () => "",
          open: Boolean(errorShowAlert),
          text: alertErrorText ? alertErrorText : "",
        }}
      />
    </Dialog>
  );
};
