import {FC, useState} from 'react'
import {IGuranteePrice} from './IGuranteePrice'
import {Box, Stack, Typography} from '@mui/material'
import {WbTwilight} from '@mui/icons-material'
import {InputEditField} from '../../../../../../components/Table/ui/Fields/InputEditField/InputEditField'
import {priceTransormFormat} from '../../../../../../shared/functions/functions'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import {IIntegrationGaranteeRequest} from '../../../../../../services/integration/IntegrationsRequest'
import {AxiosError} from 'axios'
import integrationPageStore from '../../../../../../store/integrationPage.store'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import {EmptyText} from "../../../../../../components/EmptyText/EmptyText";
import { EditIconButton } from '../../../../../../components/UI/Buttons/EditIconButton/EditIconButton'

export const GuranteePrice: FC<IGuranteePrice> = (props) => {
  const {
    readOnly,
    value,
    integrationId,
    id,
    iconColor,
    setUpdateId,
    update_id,
    isGuaranteed,
  } = props

  const [isEdit, setEdit] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const openEdit = () => {
    setEdit(true)
  }

  const closeEdit = () => {
    setEdit(false)
    setError(null)
  }

  const changePrice = (amount: string) => {
    setLoading(true)
    setError(null)

    const options: IIntegrationGaranteeRequest = {
      integrationId: integrationId,
      id: id ? id : undefined,
      amount: +amount,
      update_id,
    }
    IntegrationsService.setGarant(options)
      .then(res => {
        integrationPageStore.setGuarantee(res.data?.guarantee || null)
        integrationTableStore.changeGurange(integrationId, res.data?.guarantee || null, res.data.update_id)
        setUpdateId(res.data?.update_id)
        setEdit(false)
        setLoading(false)
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <Stack
      direction={isEdit ? 'column' : 'row'}
      alignItems={isEdit ? 'start' : 'center'}
      spacing={0.5}
    >
      {iconColor !== null &&
			 <WbTwilight sx={{color: iconColor}}/>
      }
      {isGuaranteed && <Box color={iconColor || '#34DD6E'} fontWeight={'bold'}>П</Box>}
      <Typography>Гарант на кол-во просмотров:</Typography>
      <Box sx={{width: isEdit ? '100%' : 'auto'}}>
        {!isEdit
          ? <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={0.5}
            >
              <Typography sx={{whiteSpace: 'nowrap'}}>
                {(value !== null && value !== undefined) ? priceTransormFormat(value, false) : <EmptyText/>}
              </Typography>
              {!readOnly &&
                <EditIconButton size='small' onClick={openEdit}/>
              }
            </Stack>
          : <InputEditField
              initValue={value?.toString() || ''}
              inputType='number'
              placeholder='Введите гарант просмотров'
              onClose={closeEdit}
              onSubmit={changePrice}
              error={error}
              loading={isLoading}
              fullWidth
            />
        }
      </Box>
    </Stack>
  )
}
