import { FC, useState } from "react";
import { IStatusItem } from "../models/IStatusItem";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { AlertModal } from "../../AlertModal/AlertModal";
import { StatusAgreeModal } from "./StatusAgreeModal/StatusAgreeModal";
import { priceTransormFormat } from "../../../shared/functions/functions";
import dayjs from "dayjs";

export const StatusItem: FC<IStatusItem> = (props) => {
  const {
    isNettingPaymentType,
    type,
    onClick,
    loading = false,
    statusStore,
    integrationId,
    onChangeType,
    payAlertModal = false,
    payContentData,
    wrap,
    isStopAlert,
    stopAlertText,
    readOnly,
  } = props;

  const [isAlertOpen, setAlertOpen] = useState(false);

  const currentStatusItem = statusStore[type];

  const getContrastTextColor = (backgroundColor: string) => {
    if (backgroundColor?.length === 0) {
      return;
    }
    const red = parseInt(backgroundColor?.substring(1, 3), 16);
    const green = parseInt(backgroundColor?.substring(3, 5), 16);
    const blue = parseInt(backgroundColor?.substring(5, 7), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness > 150 ? "#000000" : "#FFFFFF";
  };
  const { name } = currentStatusItem;
  const content = `${isNettingPaymentType && name === "Оплачено" ? `${name} товаром` : name}`;

  return (
    <>
      <Tooltip
        title={
          !readOnly ? (
            ""
          ) : (
            <Typography>
              Функция недоступна. Необходимо заполнить информацию о регистрации на Госуслугах.
            </Typography>
          )
        }>
        <Box
          sx={{
            background: readOnly ? "#dde0e3" : currentStatusItem.hexColor,
            p: 1,
            borderRadius: "4px",
            textAlign: "center",
            cursor: readOnly ? "default" : "pointer",
            "&:hover": { opacity: ".8" },
          }}
          onClick={() => (readOnly ? "" : setAlertOpen(true))}>
          <Typography
            color={getContrastTextColor(currentStatusItem.hexColor)}
            sx={{ whiteSpace: wrap ? "wrap" : "nowrap", lineHeight: "1" }}>
            {content}
          </Typography>
        </Box>
      </Tooltip>

      {isAlertOpen &&
        currentStatusItem.name !== "Согласование" &&
        !payAlertModal &&
        !isStopAlert && (
          <AlertModal
            open={isAlertOpen}
            onClose={() => setAlertOpen(false)}
            onSubmit={() => onClick(type)}
            title="Подтвердите действие"
            subtext={
              currentStatusItem.name === "Согласование"
                ? "При переходе в данный статус вы инициируете процесс согласования интеграции."
                : "Вы действительно хотите сменить статус?"
            }
            buttonCancelName="Отмена"
            buttonSubmitName="Сменить"
            loading={loading}
          />
        )}
      {isAlertOpen &&
        currentStatusItem.name === "Согласование" &&
        !payAlertModal &&
        !isStopAlert && (
          <StatusAgreeModal
            onChangeType={onChangeType}
            open={isAlertOpen}
            onClose={() => setAlertOpen(false)}
            integrationId={integrationId}
          />
        )}
      {isAlertOpen && payAlertModal && !isStopAlert && (
        <AlertModal
          open={isAlertOpen}
          onClose={() => setAlertOpen(false)}
          onSubmit={() => onClick(type)}
          title={type === ("paid" as any) ? "Вы действительно хотите оплатить?" : "Внимание!"}
          subtext={
            type === ("not_paid" as any) ? "Вы действительно хотите отменить оплату?" : undefined
          }
          subContent={
            payContentData &&
            type === ("paid" as any) && (
              <Stack spacing={1}>
                <Typography>Вид оплаты: {payContentData?.paymentType}</Typography>
                <Typography>
                  Сумма оплаты: {priceTransormFormat(payContentData?.totalSumm, true)}
                </Typography>
                <Typography>
                  Дата оплаты: {dayjs(payContentData?.plannedPaidAt)?.format("DD.MM.YYYY")}
                </Typography>
              </Stack>
            )
          }
          buttonCancelName={type === ("paid" as any) ? "Отмена" : "Закрыть"}
          buttonSubmitName={type === ("paid" as any) ? "Оплатить" : "Отменить оплату"}
          loading={loading}
        />
      )}
      {isAlertOpen && isStopAlert && (
        <AlertModal
          open={isAlertOpen}
          onClose={() => setAlertOpen(false)}
          onSubmit={() => setAlertOpen(false)}
          title="Внимание!"
          subtext={stopAlertText}
          buttonCancelName="Отмена"
          buttonSubmitName="закрыть"
        />
      )}
    </>
  );
};
