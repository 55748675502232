import { FC, useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { PromokodList } from "../../../../components/Promokod/PromokodList";
import { IAsideCardPromokod } from "./IAsideCardPromokod";
import {
  IInsertDummiesRequest,
  IInsertPromokodRequest,
  IIntegrationActivatePromoRequest,
  IIntegrationPromokodDelete,
} from "../../../../services/integration/IntegrationsRequest";
import IntegrationsService from "../../../../services/integration/integrations.service";
import integrationTableStore from "../../../../store/integrationTable.store";
import { AxiosError } from "axios";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import { AlertModal } from "../../../../components/AlertModal/AlertModal";
import integrationPageStore from "../../../../store/integrationPage.store";

export const AsideCardPromokod: FC<IAsideCardPromokod> = (props) => {
  const {
    dummies,
    promokod,
    id,
    isEditMode,
    readOnly,
    onChangeValue,
    onChangeSourceValue,
    onChangeUpdateId,
    isActivated,
    integrationId,
    update_id,
    setLink,
    setShortLinkChange,
    disableActiveOnSiteBtn,
    goodsCategoryCode,
    integrationIsClone,
    repeatIntegrationId
  } = props;

  const [isEdit, setEdit] = useState(false);
  const [isWithSource, setWithSource] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isShowAlert, setShowAlert] = useState(false);

  const [isLoadingDummy, setLoadingDummy] = useState(false);
  const [isShowAddModal, setShowAddModal] = useState(false);

  const [isShowActivated, setShowActivated] = useState(false);
  const [isLoadingActivated, setLoadingActivated] = useState(false);
  const [isShowSucsessAlert, setShowSucsessAlert] = useState(false);
  const [isErrorActivated, setErrorActivated] = useState<null | string>(null);

  const submit = (value: string) => {
    setLoading(true);
    setError(null);

    if (id) {
      const option: IInsertPromokodRequest = {
        id: +id,
        promokod: value.replace(/\s/g, "").toUpperCase(),
        syncWithSource: isWithSource,
      };
      IntegrationsService.insertPromokod(option)
        .then((res) => {
          // console.log(res, 'res prom field')
          onChangeValue({
            code: res.data.promokod?.code,
            dummies: res.data.promokod?.dummies,
            update_id: res.data.update_id,
            isActivated: res.data.promokod?.isActivated,
          });
          onChangeUpdateId(res.data.update_id);

          if (isWithSource) {
            integrationTableStore.insertPromokod({
              id: +id,
              promokodCode: res.data.promokod?.code,
              dummyArray: res.data.promokod?.dummies,
              update_id: res.data.update_id,
              newSource: res.data.source,
              isActivated: res.data.promokod?.isActivated,
            });
            onChangeSourceValue(res.data.source);
          } else
            integrationTableStore.insertPromokod({
              id: +id,
              promokodCode: res.data.promokod?.code,
              dummyArray: res.data.promokod?.dummies,
              update_id: res.data.update_id,
              isActivated: res.data.promokod?.isActivated,
            });

          integrationPageStore.setIsPromoForCloneChanged(res.data.isPromoForCloneChanged)

          setLink(res.data?.link);
          setShortLinkChange(res.data?.shortLink ? res.data?.shortLink : null);

          setEdit(false);
          setLoading(false);
          setWithSource(true);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, 'err prom field')
            setError(err.response?.data?.message);
            setShowAlert(true);
            setLoading(false);
          }
        });
    }
  };

  const submitActivated = (promoId: number) => {
    setLoadingActivated(true);
    setErrorActivated(null);

    const option: IIntegrationActivatePromoRequest = {
      integrationId: integrationId,
      ruleId: promoId,
    };
    IntegrationsService.integrationActivatePromo(option)
      .then((res) => {
        // console.log(res, 'res activated promo')

        integrationTableStore.insertPromokod({
          id: integrationId,
          promokodCode: res.data.code,
          dummyArray: res.data.dummies.map((x) => x?.code),
          isActivated: res.data.isActivated,
        });

        onChangeValue({
          code: res.data.code,
          dummies: res.data.dummies.map((x) => x?.code),
          update_id: update_id,
          isActivated: res.data.isActivated,
        });

        setShowActivated(false);
        setShowSucsessAlert(true);
        setLoadingActivated(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err activated promo')

          setErrorActivated(err.response?.data?.message);
          setShowActivated(false);
          setLoadingActivated(false);
        }
      });
  };

  const close = () => {
    setError(null);
    setEdit(false);
    setWithSource(true);
  };

  const addDummysPromokod = (value: string) => {
    setLoadingDummy(true);
    // setError(false)
    if (id) {
      const option: IInsertDummiesRequest = {
        mainPromo: promokod,
        dummies: [...dummies, value],
      };
      IntegrationsService.insertDummies(option)
        .then((res) => {
          // console.log(res, 'res add dummy prom')
          onChangeValue({
            code: res.data.code,
            dummies: res.data.dummies,
            isActivated: isActivated,
            update_id: update_id,
          });

          setLoadingDummy(false);
          // setError(false)
          setShowAddModal(false);

          integrationTableStore.updatePromokod(+id, res.data.dummies);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            // console.log(err, 'err dumm add')
            // setError(true)
            setLoadingDummy(false);
          }
        });
    }
  };

  const removeDummyPromokod = (mainPromo: string, removeValue: string) => {
    const option: IInsertDummiesRequest = {
      mainPromo,
      dummies: dummies.filter((item) => item !== removeValue),
    };
    IntegrationsService.insertDummies(option)
      .then((res) => {
        // console.log(res, 'res dum delete')
        integrationTableStore.updatePromokod(+id!, res.data.dummies);
        onChangeValue({
          code: res.data.code,
          dummies: res.data.dummies,
          isActivated: isActivated,
          update_id: update_id,
        });
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err dummy remove')
        }
      });
  };

  const onDeletePromokod = (id: number) => {
    const option: IIntegrationPromokodDelete = {
      id: id,
      promokod,
      isClone: integrationIsClone,
      repeatIntegrationId: repeatIntegrationId
    };
    IntegrationsService.deletePromokod(option)
      .then((res) => {
        integrationTableStore.deletePromokod(id, res.data.update_id);

        setLink(res.data.link);
        onChangeSourceValue(res.data.source);
        onChangeValue(null as any);
        onChangeUpdateId(res.data.update_id);
        setShortLinkChange(null);
        integrationPageStore.setIsPromoForCloneChanged(res.data.isPromoForCloneChanged)
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
        }
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithSource(event.target.checked);
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={{ xs: 0, sm: 1 }}
        sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}>
        <Typography>Промокод:</Typography>
        <PromokodList
          dummies={dummies}
          promokod={promokod}
          id={id}
          isEditMode={isEditMode}
          openEdit={() => setEdit(true)}
          readOnly={readOnly}
          isAddDummy={isShowAddModal}
          setAddDummy={() => setShowAddModal(true)}
          removeDummyPromokod={removeDummyPromokod}
          isActivated={isActivated}
          isShowActivatedBtn
          onDeletePromokod={id ? () => onDeletePromokod(+id) : () => ""}
          isOpenActivatedModal={isShowActivated}
          onOpenActivatedModal={() => setShowActivated(true)}
          onCloseActivatedModal={() => setShowActivated(false)}
          onSubmitActivatedModal={submitActivated}
          isLoadingActivated={isLoadingActivated}
          disableActiveOnSiteBtn={disableActiveOnSiteBtn}
          goodsCategoryCode={goodsCategoryCode}
          addDummyComponent={
            <AlertModal
              buttonCancelName={"Закрыть"}
              buttonSubmitName={"Сохранить"}
              showTextField
              title="Форма добавления промокода"
              open={isShowAddModal}
              onClose={() => setShowAddModal(false)}
              placeholder="Введите промокод"
              onSubmit={addDummysPromokod}
              loading={isLoadingDummy}
              error={error ? true : false}
              promokodFlag={true}
            />
          }
          sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}
        />
      </Stack>
      {isEdit && (
        <AlertModal
          buttonCancelName={"Закрыть"}
          buttonSubmitName={"Сохранить"}
          loading={loading}
          showTextField
          title="Форма редактирования промокода"
          textCase="uppercase"
          open={isEdit}
          onClose={close}
          onSubmit={submit}
          placeholder="Введите промокод"
          initValue={promokod}
          error={error ? true : false}
          subContent={
            <Box>
              <FormControlLabel
                control={
                  <Checkbox disabled={loading} checked={isWithSource} onChange={handleChange} />
                }
                label="Синхронизировать с источником"
              />
            </Box>
          }
          promokodFlag={true}
        />
      )}
      {error && isShowAlert && (
        <NotificationAlerts
          error={{ open: isShowAlert, onClose: () => setShowAlert(false), text: error }}
        />
      )}
      <NotificationAlerts
        sucsess={{
          text: "Промокод успешно завёлся!",
          open: isShowSucsessAlert,
          onClose: () => setShowSucsessAlert(false),
        }}
        error={{
          text: isErrorActivated ? isErrorActivated : "",
          open: isErrorActivated ? true : false,
          onClose: () => setErrorActivated(null),
        }}
      />
    </>
  );
};
