import { useState, FC, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IAsideCardRelease } from "../models/IAsideCardRelease";
import { ReleaseItem } from "../../../components/ReleaseList/ui/ReleaseItem";
import { Box, Paper, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { NotificationAlerts } from "../../../components/NotificationAlerts/NotificationAlerts";
import { IReleaseItem } from "../../../components/ReleaseList/models/IReleaseItem";
import { AsideReleaseForm } from "./AsideReleaseForm";
import { AsideCardHeader } from "../../../components/AsideCardHeader/AsideCardHeader";
import releasePlacementsStore from "../../../store/releasePlacements.store";
import integrationPageStore from "../../../store/integrationPage.store";
import { sendDatePlusCustomTime } from "../../../shared/functions/functions";
import { EmptyText } from "../../../components/EmptyText/EmptyText";
import { AddedButton } from "../../../components/UI/Buttons/AddedButton/AddedButton";
import { CustomIconButton } from "../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { OpenInNew } from "@mui/icons-material";

export const AsideCardRelease: FC<IAsideCardRelease> = observer((props) => {
  const {
    id,
    releases,
    readOnly = false,
    onAddRelease,
    onDelete,
    onEdit,
    error,
    loading,
    isShowAddRelease,
    setShowAddRelease,
    onEditPlacement,
    onCloseModalEdit,
    onClosePlacement,
    alertErrorText,
    errorShowAlert,
    closeErrorAlert,
    repeatIntegrationPromocode,
    repeatIntegrationId,
    handleOpenIntegration,
    isClone,
  } = props;

  const contentRef = useRef<HTMLElement | null>(null);

  const [isCopyAlert, setCopyAlert] = useState(false);

  const [linkValue, setLinkValue] = useState("");
  const [viewValue, setViewValue] = useState("");
  const [commentsValue, setCommentsValue] = useState("");
  const [likeValue, setLikeValue] = useState("");
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [selectValueRelease, setSelectValueRelease] = useState(
    integrationPageStore.type ? integrationPageStore.type : ""
  );
  const [selectPlacementValue, setSelectPlacementValue] = useState(
    integrationPageStore.type === "youtube"
      ? "beginning"
      : integrationPageStore.type === "telegram"
      ? "post"
      : ""
  );
  const [isDefault, setIsDefault] = useState(true);

  const defaultReleases = releases.filter((release) => release.default);
  const reissueReleases = releases.filter((release) => !release.default);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValueRelease(event.target.value as string);
  };

  const handleChangePlacement = (event: SelectChangeEvent) => {
    setSelectPlacementValue(event.target.value as string);
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyAlert(true);
  };

  const clearData = () => {
    setLinkValue("");
    setViewValue("");
    setLikeValue("");
    setCommentsValue("");
    setSelectPlacementValue("");
    setDateValue(null);
  };

  const closeRelease = () => {
    clearData();
    setShowAddRelease(false);
  };

  const addRelease = () => {
    if (linkValue.trim() !== "" && selectValueRelease.trim() !== "" && dateValue) {
      const item: IReleaseItem = {
        comments: +commentsValue || 0,
        likes: +likeValue || 0,
        link: linkValue,
        views: +viewValue || 0,
        type: { code: selectValueRelease },
        default: isDefault,
        releaseDate: sendDatePlusCustomTime({ value: dateValue?.toISOString() }),
        integrationPlace:
          releasePlacementsStore.placements?.find((x) => x.code === selectPlacementValue) ||
          (null as any),
      };
      onAddRelease(item);
    }
  };

  const scrollDown = () => {
    contentRef?.current?.scrollTo({ top: contentRef?.current.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    scrollDown();
  }, [releases]);

  useEffect(() => {
    if (!isShowAddRelease) {
      clearData();
    }
  }, [isShowAddRelease]);

  useEffect(() => {
    if (integrationPageStore.type && selectValueRelease === "") {
      setSelectPlacementValue(
        integrationPageStore.type === "youtube"
          ? "beginning"
          : integrationPageStore.type === "telegram"
          ? "post"
          : ""
      );
    } else {
      setSelectPlacementValue(
        selectValueRelease === "youtube"
          ? "beginning"
          : selectValueRelease === "telegram"
          ? "post"
          : ""
      );
    }
  }, [selectValueRelease]);

  useEffect(() => {
    if (isClone) {
      setIsDefault(false);
    }
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        pb: 1,
        pt: 2,
        border: "none",
        boxShadow: "0px 0px 8px 0px rgba(34, 60, 80, 0.2)",
        overflow: "auto",
        height: "fit-content",
      }}>
      <Box sx={{ pl: 2, pr: 2 }}>
        <AsideCardHeader
          title={`Релизы ${releases.length > 0 ? `(${releases.length})` : ""}`}
          rightContent={
            !isShowAddRelease &&
            !readOnly && (
              <>
                {(!isClone || repeatIntegrationPromocode) && (
                  <AddedButton variant="outlined" onClick={() => setShowAddRelease(true)}>
                    Добавить релиз
                  </AddedButton>
                )}
                {!repeatIntegrationPromocode && isClone && (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography fontWeight={"bold"}>
                      добавить в интеграцию {repeatIntegrationId}
                    </Typography>
                    <CustomIconButton onClick={handleOpenIntegration}>
                      <OpenInNew />
                    </CustomIconButton>
                  </Stack>
                )}
              </>
            )
          }
        />
      </Box>
      {releases.length === 0 && (
        <EmptyText variant="body1" noWrap sx={{ ml: 2, mr: 2, mt: 1 }}>
          В настоящий момент нет релизов
        </EmptyText>
      )}
      <Box ref={contentRef} sx={{ pl: 2, pr: 2, mb: 2, maxHeight: "260px", overflow: "auto" }}>
        {defaultReleases.length > 0 &&
          defaultReleases.map((link, i) => (
            <ReleaseItem
              id={link.id}
              key={link.id}
              link={link.link}
              onCopyText={() => copyText(link.link)}
              type={link.type}
              releaseDate={link.releaseDate}
              comments={link.comments}
              likes={link.likes}
              views={link.views}
              default={link.default}
              integrationPlace={link.integrationPlace}
              lastUpdate={link.lastUpdate}
              wrap
              minWidth="300px"
              onDelete={onDelete}
              onEdit={onEdit}
              isEditMode
              error={error}
              loading={loading}
              readOnly={readOnly || (isClone && !repeatIntegrationPromocode)}
              onChangePlacement={onEditPlacement}
              onCloseModalEdit={onCloseModalEdit}
              onClosePlacement={onClosePlacement}
              alertErrorText={alertErrorText}
              errorShowAlert={errorShowAlert}
              closeErrorAlert={closeErrorAlert}
              isMain={i === 0}
              isDefault={true}
            />
          ))}
        {reissueReleases.length > 0 &&
          reissueReleases.map((link, i) => (
            <ReleaseItem
              id={link.id}
              key={link.id}
              link={link.link}
              onCopyText={() => copyText(link.link)}
              type={link.type}
              releaseDate={link.releaseDate}
              comments={link.comments}
              likes={link.likes}
              views={link.views}
              default={link.default}
              integrationPlace={link.integrationPlace}
              lastUpdate={link.lastUpdate}
              wrap
              minWidth="300px"
              onDelete={onDelete}
              onEdit={onEdit}
              isEditMode
              error={error}
              loading={loading}
              readOnly={readOnly || (isClone && !repeatIntegrationPromocode)}
              onChangePlacement={onEditPlacement}
              onCloseModalEdit={onCloseModalEdit}
              onClosePlacement={onClosePlacement}
              alertErrorText={alertErrorText}
              errorShowAlert={errorShowAlert}
              closeErrorAlert={closeErrorAlert}
              isMain={i === 0 && defaultReleases.length === 0}
              isDefault={false}
              separator={i === 0}
            />
          ))}
      </Box>
      {!readOnly && (
        <>
          {isShowAddRelease && (
            <Box sx={{ p: 1, pb: 0 }}>
              <AsideReleaseForm
                typeValue={integrationPageStore.type}
                loading={loading}
                error={error}
                commentsValue={commentsValue}
                onChangeComments={(e) => setCommentsValue(e.target.value)}
                likeValue={likeValue}
                onChangeLike={(e) => setLikeValue(e.target.value)}
                linkValue={linkValue}
                onChangeLink={(e) => setLinkValue(e.target.value)}
                viewValue={viewValue}
                onChangeView={(e) => setViewValue(e.target.value)}
                selectValueRelease={selectValueRelease}
                isDefault={isDefault}
                onChangeIsDefault={(e) => setIsDefault(e.target.value === "true")}
                onChangeSelect={handleChange}
                dateValue={dateValue}
                onChangeDate={setDateValue}
                onClose={closeRelease}
                onSubmit={addRelease}
                timeValue={dateValue}
                onChangeTime={setDateValue}
                placementValue={selectPlacementValue}
                onChangePlacement={handleChangePlacement}
                hasClone={!repeatIntegrationPromocode && !!repeatIntegrationId}
              />
            </Box>
          )}
        </>
      )}
      <NotificationAlerts
        sucsess={{
          text: "Успешно скопировалось!",
          open: isCopyAlert,
          onClose: () => setCopyAlert(false),
        }}
      />
    </Paper>
  );
});
