import { FC } from "react";
import { IOplataFullBlock } from "./IOplataFullBlock";
import { Box, Stack } from "@mui/material";
import { OplataCard } from "./ui/OplataCard/OplataCard";
import { observer } from "mobx-react-lite";
import { ContragentIntegration } from "./ui/ContragentIntegration/ContragentIntegration";
import { ContractIntegration } from "./ui/ContractIntegration/ContractIntegration";
import { PaymentIntegrations } from "./ui/PaymentIntegrations/PaymentIntegrations";
import { BlockedCard } from "../../../../../FinanceCardPage/ui/BlockedCard/BlockedCard";
import integrationPageStore from "../../../../../../store/integrationPage.store";
import { ChatComments } from "./ui/ChatComments/ChatComments";
import { EndDocs } from "./ui/EndDocs/EndDocs";
import { OrdIntegrationBlock } from "./ui/OrdIntegrationBlock/OrdIntegrationBlock";
import { IntegrationPaymentTotalSumm } from "./ui/IntegrationTotalSumm/IntegrationPaymentTotalSumm";
import { editPaymentsPermission } from "../../../../../../shared/rules/permission";

export const OplataFullBlock: FC<IOplataFullBlock> = observer((props) => {
  const { readOnly, id, onChangeCpv, setPriceValue, setUpdateId, update_id } = props;

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "100%",
            lg: `calc(${integrationPageStore.chatOpenPayment ? "75%" : "95%"} - 16px) ${
              integrationPageStore.chatOpenPayment ? "25%" : "5%"
            }`,
          },
          columnGap: 2,
          transition: "all ease .3s",
        }}>
        <Box>
          <Box>
            {integrationPageStore.isOplataBlocked && (
              <Box sx={{ mb: 2 }}>
                <BlockedCard
                  title={
                    integrationPageStore?.status?.code === "cancel" ? "Оплата отменена" : undefined
                  }
                  text={
                    integrationPageStore?.status?.code === "cancel"
                      ? 'Невозможно оплатить оплату в статусе "Отмена"'
                      : undefined
                  }
                />
              </Box>
            )}
            {editPaymentsPermission() && (
              <Box
                sx={{
                  mb: 2,
                  display: "grid",
                  gridTemplateColumns: { xs: "100%", lg: "calc(75% - 16px) 25%" },
                }}>
                <BlockedCard
                  title={"Нет права редактирования"}
                  text={
                    "Поскольку Вы не являетесь выпускающим менеджером интеграции, редактирование данной оплаты Вам недоступно"
                  }
                />
              </Box>
            )}
            <IntegrationPaymentTotalSumm
              integrationId={id}
              integrations={integrationPageStore.integrations || []}
              totalPrice={integrationPageStore.totalSumm}
              errorPayments={integrationPageStore.errorPayments}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "grid",
              gridTemplateColumns: { xs: "100%", md: "calc(50% - 16px) 50%" },
              rowGap: 2,
              columnGap: 2,
            }}>
            <Stack spacing={2}>
              <OplataCard readOnly={readOnly || editPaymentsPermission()} integrationId={id} />
              <ContragentIntegration readOnly={readOnly} />
              <ContractIntegration readOnly={readOnly} />
              <EndDocs
                integrationStatusCode={integrationPageStore.status?.code}
                readOnly={readOnly}
                integrationId={id}
              />
            </Stack>
            <Stack spacing={2}>
              <Box sx={{ height: "fit-content" }}>
                <PaymentIntegrations readOnly={readOnly} integrationId={id} />
              </Box>
              <OrdIntegrationBlock
                readOnly={
                  integrationPageStore.isPromoForCloneChanged
                    ? !integrationPageStore.isPromoForCloneChanged
                    : readOnly
                }
                integrationId={id}
              />
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            mt: { xs: 2, sm: 2, md: 2, lg: 0 },
            width: { xs: "100%", md: "calc(50% - 16px)", lg: "100%" },
          }}>
          <ChatComments />
        </Box>
      </Box>
    </>
  );
});
